
import { defineComponent } from 'vue';
import marked from 'marked';

export default defineComponent({
  name: 'MarkdownWidget',
  components: {
  },
  props: {
    showEditTools: { default: false },

    config: { default: () => ({ width: 1, classes: '', markdownSource: () => '' }), type: Object },
    dbData: { default: () => ({}), type: Object },
  },
  computed: {
    markdownHtml() {
      return marked(this.config.markdownSource(this.dbData) || '');
    },
    configClasses() {
      return `md:col-span-${this.config.width} ${this.config.classes || ''}`;
    },
  },
});
