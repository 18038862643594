/* eslint-disable max-len */
import { shallowRef } from 'vue';
import jwtDecode from 'jwt-decode';
import lodash, {
  entries, has, merge, keys, values, fromPairs, unzip, includes,
} from 'lodash';
/* eslint-disable-next-line import/no-cycle */
import { getDataPoint } from '@/lib/network';
import { formatDate, formatDateRelative } from '@/lib/datetime';
import {
  ILoggedEventParsed, ILoggedEventRaw, ILoggedRequestParsed, ILoggedRequestRaw,
} from '@/lib/interfaces';
import { LIST_REGEX_SERVER_PATHS } from '@/lib/constants';

import MarkdownWidget from '@/components/widgets/MarkdownWidget.vue';
import TagWidget from '@/components/widgets/TagWidget.vue';
import SpaceWidget from '@/components/widgets/SpaceWidget.vue';
import TableWidget from '@/components/widgets/TableWidget.vue';
import StatCardWidget from '@/components/widgets/StatCardWidget.vue';
import RouterLinkWidget from '@/components/widgets/RouterLinkWidget.vue';

/* =================================================================================================
                    INTERFACCE
*/

/*
  Questo e' un IConfigTemplate
  {
    codice: (s : string) => s,
    type: (s : string) => ({ table: 'extradata', machineId: s, key: 'type' }),
  }

  composto da chiavi di tipo string e valori di tipo IConfigTemplateFunction.
  Una volta che una IConfigTemplateFunction viene particolarizzata passandogli come
  argomento un seriale, si ottiene un valore che puo' essere IConfigTemplateValueLocal
  oppure IConfigTemplateValueRemote, nei casi di codice e type rispettivamente.

  IConfigTemplateFunction       (s : string) => s
  IConfigTemplateFunction       (s : string) => ({ table: 'extradata', machineId: s, key: 'type' })

  IConfigTemplateValueLocal     'seriale'
  IConfigTemplateValueRemote    { table: 'extradata', machineId: 'seriale', key: 'type' }

  Quindi abbiamo il tipo IConfig
  {
    codice: 'seriale',
    type: { table: 'extradata', machineId: 'seriale', key: 'type' },
  }

  Che una volta riempito con i dati ottenuto dal database sara' del tipo IFilledConfig
  {
    codice: 'seriale',
    type: 'tshape2',
  }
*/

type IConfigTemplateValueLocal = string | number

interface IConfigTemplateValueRemote {
  table : string,
  machineId : string,
  key : string,
}

/**
 * Funzione presente come valore in un dict ConfigTemplate
 */
interface IConfigTemplateFunction {
  (seriale : string) : IConfigTemplateValueLocal | IConfigTemplateValueRemote
}

/**
 * Rappresenta una configurazione generica e non ancora particolarizzata
 */
export type IConfigTemplate = Record<string, IConfigTemplateFunction>

type IConfig = Record<string, IConfigTemplateValueLocal | IConfigTemplateValueRemote>

type IConfigRemote = Record<string, IConfigTemplateValueRemote>

type IFilledConfigValue = string | number

export type IFilledConfig = Record<string, IFilledConfigValue>

/* =================================================================================================
                    FUNZIONI DI RECUPERO DATI DAL BACKEND
*/

/**
 * Dato un dict contente alcuni dati da richiedere al server, li recupera e li
 *  ritorna
 */
export const getData = (data : IConfigRemote) : Promise<IFilledConfig> => {
  /* I dati sono nella seguente forma
  {
    "key1": <elemento che descrive il dato da ricevere>,
    "key2": <elemento che descrive il dato da ricevere>,
    "key3": <elemento che descrive il dato da ricevere>,
  }

  Dove ogni elemento e' fatto nel seguente modo { "type": "", "machineId": "", "key": "" }
  */

  const dataKeys = keys(data);
  const promises = values(data).map((req) => getDataPoint(req.table, req.machineId, req.key));

  return Promise
    .all(promises)
    .then((results) => fromPairs(unzip([dataKeys, results])))
    .catch(() => ({}));
};

/**
 * Determina se un config e' remota oppure no
 * Per adesso si guarda semplicemente se e' presente il campo `table`, che indica che
 * la config e' remota.
 * @param config Configurazione da testare
 * @returns true se e' remota, false se non lo e'
 */
const isRemoteConfig = (config : IConfigTemplateValueLocal | IConfigTemplateValueRemote) : config is IConfigTemplateValueRemote => (
  has(config, 'table')
);

/**
 * Dato in ingresso un template di configurazione lo analizza
 * @param configTemplate Template in ingresso
 */
export const fillDataConfigTemplate = async (seriale : string, configTemplate : IConfigTemplate) : Promise<IFilledConfig> => {
  /* Questi due dict verranno popolati uno con le configurazioni che necessitano di andare
    a leggere il database, e l'altro con quelle che possono essere completate con i valori
    gia' presenti */
  const configRemote: IConfigRemote = {};
  const configLocal: IFilledConfig = {};

  entries(configTemplate).forEach((d) => {
    const key = d[0];
    const val : IConfigTemplateValueRemote | IConfigTemplateValueLocal = d[1](seriale);

    if (isRemoteConfig(val)) {
      configRemote[key] = val;
    } else {
      configLocal[key] = val;
    }
  });

  /* Adesso dentro configRemote io ho il dict che posso passare a
    getData per recuperare i dati dal database */
  const data : IFilledConfig = await getData(configRemote);
  return merge(data, configLocal);
};

export const fillArrayDataConfigTemplate = (listaSeriali : readonly string[], configTemplate : IConfigTemplate) : Promise<IFilledConfig[]> => (
  Promise
    .all(listaSeriali.map((seriale : string) => fillDataConfigTemplate(seriale, configTemplate)))
    .then((tmp) => tmp)
    .catch(() => ([]))
);

/* ============================================================================================== */
/* PARSING DELLE CONFIGURAZIONI DELLE PAGINE */

const getWidgetShallowRef = (type: string) : any => {
  if (type === 'SpaceWidget') { return shallowRef(SpaceWidget); }
  if (type === 'MarkdownWidget') { return shallowRef(MarkdownWidget); }
  if (type === 'TagWidget') { return shallowRef(TagWidget); }
  if (type === 'TableWidget') { return shallowRef(TableWidget); }
  if (type === 'StatCardWidget') { return shallowRef(StatCardWidget); }
  if (type === 'RouterLinkWidget') { return shallowRef(RouterLinkWidget); }
  return '';
};

const lodashGet = (obj: any, path: string) => (
  lodash.get(obj, path)
);

export const parsePageConfiguration = (textConfiguration: string) : any => {
  let jsonConfiguration;
  const parsedConfiguration = { widgets: [] as any[] };
  const LIST_EVAL_PROPS = ['markdownSource', 'to'];
  const EVAL_FUNCTION_CREATOR = (funcBody : any) => `(d) => { return ${funcBody}; }`;

  /* Rimuovo eventuali commenti dalla stringa */
  // eslint-disable-next-line no-useless-escape
  const textConfigurationNoComments = textConfiguration.replace(new RegExp('\\\/\\\*[\s\S]*\\\*\\\/|//.*', 'g'), '');

  try {
    jsonConfiguration = JSON.parse(textConfigurationNoComments);
  } catch {
    jsonConfiguration = { widgets: [{ type: 'MarkdownWidget', config: { markdownSource: '\'**Errore parsing**\'' } }] };
  }

  (jsonConfiguration.widgets as any[] || []).forEach((d) => {
    const widgetConfiguration : Record<string, any> = {
      type: getWidgetShallowRef(d.type),
      config: {},
    };

    Object.keys(d.config ?? {}).forEach((dd) => {
      if (includes(LIST_EVAL_PROPS, dd)) {
        // eslint-disable-next-line no-eval
        widgetConfiguration.config[dd] = eval(EVAL_FUNCTION_CREATOR(d.config[dd]));
      } else {
        widgetConfiguration.config[dd] = d.config[dd];
      }
    });

    parsedConfiguration.widgets.push(widgetConfiguration);
  });

  return parsedConfiguration;
};

/* ============================================================================================== */
/* STRUMENTI PER SVILUPPATORI */

export const parseDataLoggedRequests = (requestRaw: ILoggedRequestRaw): ILoggedRequestParsed => {
  const requestParsed = {
    timestamp: requestRaw.timestamp,
    timestamp_human: `${formatDate(requestRaw.timestamp)} (${formatDateRelative(requestRaw.timestamp)})`,
    id_project: requestRaw.id_project,
    trace_id: requestRaw.trace_id,
    request: JSON.parse(requestRaw.request),
    response: JSON.parse(requestRaw.response),
    method: requestRaw.method,
    status_code: requestRaw.status_code,

    countErrors: 0,
    isPathCorrect: false,
    isMethodCorrect: true,
    isQueryStringCorrect: true,
    isJsonPayloadCorrect: true,
  } as ILoggedRequestParsed;

  /* Arricchisco i dati con informazioni aggiuntive */
  requestParsed.request.headers = requestParsed.request.headers.map((dd) => (
    typeof dd === 'string' ? dd.split(': ') : []
  ));
  requestParsed.queryPath = `${requestParsed.request.path}${requestParsed.request.querystring}`;
  requestParsed.hasErrorStatus = requestParsed.response.status_code >= 400
    && requestParsed.response.status_code <= 599;

  requestParsed.errorDescription = '';

  if (requestParsed.hasErrorStatus) {
    requestParsed.errorDescription += `È stato ritornato il codice di errore ${requestParsed.response.status_code}\n`;
    requestParsed.countErrors += 1;
  }

  /* Scorro gli header
  - per vedere se, in caso il content-type fosse application/json, il
  body della richiesta sia effettivamente una stringa json corretta
  - per estrarre i claim jwt */
  let isContentTypeFound = false;
  requestParsed.request.headers.forEach((dd) => {
    /* Controllo se e' presente l'header Content-Type, che deve sempre esserci ed eventualmente
    se il json e' corretto */
    if (dd[0].toLowerCase() === 'content-type') {
      isContentTypeFound = true;

      if (includes(dd[1].toLowerCase(), 'application/json')) {
        try {
          JSON.parse(requestParsed.request.body);
        } catch (SyntaxError) {
          requestParsed.isJsonPayloadCorrect = false;
          requestParsed.countErrors += 1;
        }
      }
    }

    if (dd[0].toLowerCase() === 'authorization') {
      requestParsed.claims = jwtDecode(dd[1].replace('Bearer ', ''));
    }
  });

  if (!isContentTypeFound && requestParsed.method === 'POST') {
    requestParsed.errorDescription += 'La richiesta è POST, ma manca l\'header \'Content-Type\'\n';
    requestParsed.countErrors += 1;
  }

  if (!requestParsed.isJsonPayloadCorrect) {
    requestParsed.errorDescription += 'Il Content-Type è application/json, ma il body della richiesta non è una stringa json corretta\n';
    requestParsed.countErrors += 1;
  }

  /* eslint-disable-next-line no-restricted-syntax */
  for (const d of Object.values(LIST_REGEX_SERVER_PATHS)) {
    if (d[0].test(requestParsed.request.path)) {
      requestParsed.isPathCorrect = true;

      if (requestParsed.request.method !== d[1]) {
        requestParsed.isMethodCorrect = false;
        requestParsed.errorDescription += `La richiesta è ${requestParsed.request.method}, mentre dovrebbe essere ${d[1]}\n`;
        requestParsed.countErrors += 1;
      }

      /* Controllo che tutti i parametri siano validi */
      const paramsUrl = [...requestParsed.request.querystring.matchAll(/[?&](\w+)=/g)].map((dd) => dd[1]);

      /* Controllo che i parametri obbligatori siano tutti presenti */
      /* eslint-disable-next-line no-restricted-syntax */
      for (const param of Object.values(d[2])) {
        if (!includes(paramsUrl, param)) {
          requestParsed.isQueryStringCorrect = false;
          requestParsed.errorDescription += `Il parametro ${param} è obbligatorio ma non è presente\n`;
          requestParsed.countErrors += 1;
        }
      }

      /* eslint-disable-next-line no-restricted-syntax */
      for (const param of paramsUrl) {
        if (!includes(d[2], param) && !includes(d[3], param)) {
          requestParsed.errorDescription += `Il parametro ${param} non è riconosciuto\n`;
          requestParsed.isQueryStringCorrect = false;
          requestParsed.countErrors += 1;
        }
      }

      break;
    }
  }

  if (!requestParsed.isPathCorrect) {
    requestParsed.errorDescription += `Il percorso "${requestParsed.request.path}" non è stato riconosciuto\n`;
    requestParsed.countErrors += 1;
  }

  return requestParsed;
};

export const parseLoggedEvent = (eventRaw: ILoggedEventRaw) : ILoggedEventParsed => {
  const eventParsed = {
    ...eventRaw,
    timestamp_human: `${formatDate(eventRaw.timestamp)} (${formatDateRelative(eventRaw.timestamp)})`,
  } as ILoggedEventParsed;

  if (eventRaw.event_name === 'login') {
    eventParsed.name = 'Login';
  }

  if (eventRaw.event_result === 'ok') {
    eventParsed.result = 'Ok';
    eventParsed.isError = false;
  } else if (eventRaw.event_result === 'fail') {
    eventParsed.result = 'Fallito';
    eventParsed.isError = true;
  }

  return eventParsed;
};
