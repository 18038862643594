
import { defineComponent } from 'vue';
import { toPairs, filter, last } from 'lodash';
import { getLoggedRequests } from '@/lib/network';
import { ILoggedRequestParsed } from '@/lib/interfaces';
import Button from '@/components/ui/Button.vue';
import Table, { ITableConfig, ICell, IRow } from '@/components/ui/Table.vue';
import Modal from '@/components/ui/Modal.vue';

export default defineComponent({
  name: 'LoggedRequests',
  components: {
    Button,
    Table,
    Modal,
  },
  data() {
    return {
      toPairs,
      filter,

      page: 0,
      showModalHelp: false,

      showModalFilter: false,
      filterErrors: 0,
      filterMethod: '',
      filterEntityId: '',
      filterEntityType: 'machine',

      configTable: {
        columns: [
          { header: 'Timestamp', path: 'timestamp_human', size: 2 },
          {
            header: 'Metodo',
            path: 'request.method',
            size: 1,
            modifier: (d: ICell, dd: IRow) => (!dd.isMethodCorrect ? 'text-red-500 font-bold bg-red-200 bg-opacity-50' : ''),
          },
          {
            header: 'Url',
            path: 'queryPath',
            size: 5,
            modifier: (d: ICell, dd: IRow) => (!dd.isPathCorrect ? 'text-red-500 font-bold bg-red-200 bg-opacity-50' : ''),
          },
          {
            header: 'Codice risposta',
            path: 'response.status_code',
            size: 1,
            modifier: (d: ICell, dd: IRow) => (dd.hasErrorStatus ? 'text-red-500 font-bold bg-red-200 bg-opacity-50' : 'text-green-700'),
          },
          /* { non penso serva per ora, e mi serve spazio nella tabella
            header: 'Num. errori',
            path: 'countErrors',
            size: 1,
            modifier: (d: ICell, dd: IRow) => (dd.countErrors > 0
            ? 'text-red-500 font-bold bg-red-200 bg-opacity-50' : 'text-green-700'),
          }, */
        ],
        actions: [
          { event: 'view-detail-request', icon: 'fas fa-file' },
        ],
      } as ITableConfig,

      showModalDetails: false,
      selectedRequest: {} as ILoggedRequestParsed,

      configTableDetails: {
        columns: [
          { header: 'Proprietà', path: '[0]', size: 1 },
          {
            header: 'Valore',
            path: '[1]',
            size: 5,
            classes: 'break-all',
          },
        ],
      } as ITableConfig,

      listRequests: [] as ILoggedRequestParsed[],
    };
  },
  mounted() {
    this.refreshData(0);
  },
  methods: {
    refreshData(page: number) : void {
      if (page === -1) {
        return;
      }
      if (this.listRequests.length === 0 && this.page > 0 && page > this.page) {
        return;
      }

      this.page = Math.max(page, 0);

      getLoggedRequests(this.page * 25, 25, this.filterErrors, this.filterMethod,
        this.filterEntityType, this.filterEntityId)
        .then((data) => {
          this.listRequests = data;
        });
    },
    openModalDetail(event: any): void {
      this.selectedRequest = event;
      this.showModalDetails = true;
    },
    createCurlCommand(request : ILoggedRequestParsed) : string {
      let curlCommand = `curl -vvv -X ${request.method}`;
      curlCommand += ` "${request.request.host}${request.request.path}${request.request.querystring}" \\\n`;

      request.request.headers.slice(0, -1).forEach((d) => {
        curlCommand += `\t-H '${d[0]}: ${d[1]}' \\\n`;
      });
      if (last(request.request.headers)) {
        curlCommand += `\t-H '${last(request.request.headers)?.[0]}: ${last(request.request.headers)?.[1]}'`;
      }

      if (request.request.body.length > 0) {
        curlCommand += ` \\\n  --data '${request.request.body}'`;
      }

      return curlCommand;
    },
  },
});
