
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';
import { ISedutaEffettuata } from '@/lib/interfaces';
import { getDataPoint, queryDataPointsByKeyPart } from '@/lib/network';
import { getData } from '@/lib/data';

export default defineComponent({
  name: 'TableErrori',
  data() {
    return {
      listaSeduteEffettuate: [] as ISedutaEffettuata[],
      errori: [] as Record<number, unknown>[],
      tableData: [],
    };
  },
  async created() {
    await this.loadData();
  },
  components: {
    Table,
  },
  props: {
    machineData: {
      type: Object,
      required: true,
    },
    tipoMacchina: {
      type: String,
      required: true,
    },
  },
  methods: {
    async loadData() {
      this.tableData = await this.getTableData();
    },

    async getTableData() {
      let baseData: any = [];
      const list: any[] = [];
      if (!this.machineData) return [];
      // TOTAL BODY - CELLUTONIC - BODY PLUS
      if (
        this.tipoMacchina === 'TB'
        || this.tipoMacchina === 'CT'
        || this.tipoMacchina === 'BP'
      ) {
        baseData = [
          {
            label: 'Errori manipolo giallo',
            value: this.machineData?.heartbeat?.errori_giallo,
          },
          {
            label: 'Errori manipolo rosso',
            value: this.machineData?.heartbeat?.errori_rosso,
          },
          {
            label: 'Errori RF',
            value: this.machineData?.heartbeat?.errori_rf,
          },
          {
            label: 'Errori US',
            value: this.machineData?.heartbeat?.errori_us,
          },
          {
            label: 'Errori OC US',
            value: this.machineData?.heartbeat?.err_OC_US,
          },
          {
            label: 'Errori Corr US',
            value: this.machineData?.heartbeat?.err_corr_US,
          },
        ];
      }

      // TOTAL FACE - LIFTONIC
      if (this.tipoMacchina === 'TF' || this.tipoMacchina === 'LT') {
        baseData = [
          {
            label: 'Errori RF',
            value: this.machineData?.heartbeat?.errori_rf,
          },
          {
            label: 'Errori US',
            value: this.machineData?.heartbeat?.errori_us,
          },
          {
            label: 'Errori OC US',
            value: this.machineData?.heartbeat?.err_OC_US,
          },
          {
            label: 'Errori Corr US',
            value: this.machineData?.heartbeat?.err_corr_US,
          },
        ];
      }

      // FACE & BODY
      if (this.tipoMacchina === 'FB') {
        baseData = [
          {
            label: 'Errori manipolo giallo',
            value: this.machineData?.heartbeat?.errori_giallo,
          },
          {
            label: 'Errori RF',
            value: this.machineData?.heartbeat?.errori_rf,
          },
          {
            label: 'Errori US',
            value: this.machineData?.heartbeat?.errori_us,
          },
          {
            label: 'Errori OC US',
            value: this.machineData?.heartbeat?.err_OC_US,
          },
          {
            label: 'Errori Corr US',
            value: this.machineData?.heartbeat?.err_corr_US,
          },
        ];
      }

      // XTONE
      if (this.tipoMacchina === 'XT') {
        baseData = [
          {
            label: 'Errori Telecomando',
            value: this.machineData?.heartbeat?.errori_telec,
          },
          {
            label: 'Errori X-Tone',
            value: this.machineData?.heartbeat?.errori_xtone,
          },
        ];
      }

      // XPRESS (TODO: fix this - it works but it's the worst code I've ever written)
      if (this.tipoMacchina === 'XP') {
        const listaClienti = await queryDataPointsByKeyPart(
          'json',
          this.$route.params.machineId as string,
          'scheda_cliente',
          100,
          true,
        );

        const promises: Promise<void>[] = [];

        listaClienti.forEach((cliente: any) => {
          const promise = queryDataPointsByKeyPart(
            'json',
            this.$route.params.machineId as string,
            `trattamento_${cliente.id_measurement}`,
            1,
            true,
          ).then((sedutacliente) => {
            if (+sedutacliente[0].Errore !== 0) {
              list.push({
                Errore: sedutacliente[0].value.Errore,
                Data: sedutacliente[0].timestamp,
              });
            }
          });

          promises.push(promise);
        });

        return Promise.all(promises)
          .then(() => {
            list.sort(
              (a: any, b: any) => +new Date(b.Data) - +new Date(a.Data),
            );
            const lastTreatment = list[0];
            baseData = [
              {
                label: 'Errore',
                value: lastTreatment ? lastTreatment.Errore : 0,
              },
            ];
            return baseData;
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }

      return baseData;
    },
    getTableConfig() {
      if (this.tipoMacchina === 'XP') {
        return {
          columns: [
            { header: 'Health', path: 'label' },
            { header: 'Settore', path: 'value' },
          ],
        };
      }
      return {
        columns: [
          { header: 'Errori', path: 'label' },
          { header: 'N.', path: 'value' },
        ],
      };
    },
  },
});
