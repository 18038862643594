import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen" }
const _hoisted_2 = { class: "px-lg-5 py-0 min-h-screen w-full h-full flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideBar, {
      class: _normalizeClass(["fixed h-screen z-20 sideBar", { 'sidebar-open': _ctx.isOpen, 'sidebar-collapsed': !_ctx.isOpen }]),
      isOpen: _ctx.isOpen,
      onToggleSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSidebar()))
    }, null, 8, ["isOpen", "class"]),
    _createElementVNode("div", {
      class: _normalizeClass(["main-body", { 'sidebar-open': _ctx.isOpen, 'sidebar-collapsed': !_ctx.isOpen }])
    }, [
      _createVNode(_component_NavBar, {
        onToggleSidebar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleSidebar()))
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, { class: "flex-grow" })
      ])
    ], 2)
  ]))
}