
import { defineComponent } from 'vue';
import * as vega from 'vega';
import {
  Axis, LinearScale, Mark, PointScale, Scale, ValuesData,
} from 'vega';

export default defineComponent({
  name: 'ChartDisc2LinWidget',
  components: {
  },
  props: {
    id: { type: String, required: true },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    height: { type: Number, required: true },
    data: { type: Array, required: true },
  },
  data() {
    return {
      spec: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',

        width: 400,
        height: this.height,
        padding: 5,

        data: [
          {
            name: 'values',
            values: [],
          },
          {
            name: 'values0',
            source: 'values',
            transform: [
              { type: 'filter', expr: 'datum.g === 0' },
            ],
          },
          {
            name: 'values1',
            source: 'values',
            transform: [
              { type: 'filter', expr: 'datum.g === 1' },
            ],
          },
          {
            name: 'values2',
            source: 'values',
            transform: [
              { type: 'filter', expr: 'datum.g === 2' },
            ],
          },
          {
            name: 'values3',
            source: 'values',
            transform: [
              { type: 'filter', expr: 'datum.g === 3' },
            ],
          },
        ] as ValuesData[],

        scales: [
          {
            name: 'xscale',
            type: 'point',
            domain: { data: 'values', field: 'x' },
            range: 'width',
            padding: 0.25,
          } as PointScale,
          {
            name: 'yscale',
            type: 'linear',
            domain: { data: 'values', field: 'y' },
            nice: true,
            range: 'height',
          } as LinearScale,
        ] as Scale[],

        axes: [
          { orient: 'bottom', scale: 'xscale' },
          {
            orient: 'left',
            scale: 'yscale',
            tickCount: 5,
            grid: true,
          },
        ] as Axis[],

        marks: [
          {
            type: 'line',
            name: 'line3',
            from: { data: 'values3' },
            encode: {
              update: {
                x: { scale: 'xscale', field: 'x' },
                y: { scale: 'yscale', field: 'y' },
              },
            },
          },
          {
            type: 'line',
            name: 'line2',
            from: { data: 'values2' },
            encode: {
              update: {
                x: { scale: 'xscale', field: 'x' },
                y: { scale: 'yscale', field: 'y' },
              },
            },
          },
          {
            type: 'line',
            name: 'line1',
            from: { data: 'values1' },
            encode: {
              update: {
                x: { scale: 'xscale', field: 'x' },
                y: { scale: 'yscale', field: 'y' },
              },
            },
          },
          {
            type: 'line',
            name: 'line0',
            from: { data: 'values0' },
            encode: {
              update: {
                x: { scale: 'xscale', field: 'x' },
                y: { scale: 'yscale', field: 'y' },
              },
            },
          },
        ] as Mark[],
      },
    };
  },
  watch: {
    data() {
      this.spec.data[0].values = this.data;
      this.spec.width = (document.getElementById(this.id)?.offsetWidth ?? 500)
        - 5 * 2 - 20 * 2;

      const view = new vega.View(vega.parse(this.spec), {
        renderer: 'svg',
        container: `#${this.id}`,
        hover: true,
      });

      view.runAsync();
    },
  },
});
