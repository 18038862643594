/*
 * Le traduzioni sono organizzate in modo da avere un dizionario con le frasi comuni
 * che si trovano in piu' pagine (come ad es la scritta Aggiorna ecc) e poi un dizionario
 * per ogni pagina
 *
 * Il modello delle traduzioni (cioe' quali stringhe devono essere tradotte) e' la traduzione it.
 * Cioe' tutte le altre traduzioni devono replicare esattamente la struttura della traduzione
 * italiana, quindi se si aggiunge una stringa nella traduzione italiana e ci si dimentica di
 * aggiungerla anche nelle altre in main.ts viene segnalato un errore di compilazione
 */

export default {
  /* Traduzioni in Italiano */
  it: {
    common: {
      aggiorna: 'Aggiorna',
    },
    reportErrori: {
      titolo: 'Macchine con errori',
    },
  },
  /* Traduzioni in Inglese */
  en: {
    common: {
      aggiorna: 'Refresh',
    },
    reportErrori: {
      titolo: 'Machines with errors',
    },
  },
};
