import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-span-2 d-lg-block d-none text-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagWidget = _resolveComponent("TagWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.pathImageBasedOnSeriale,
      alt: "Immagine"
    }, null, 8, _hoisted_2),
    _createVNode(_component_TagWidget, {
      config: _ctx.config,
      dbData: _ctx.dbData
    }, null, 8, ["config", "dbData"])
  ]))
}