import axios, { AxiosError, AxiosResponse } from 'axios';
import { get, includes } from 'lodash';
import { useToast } from 'vue-toastification';
import { DateTime } from 'luxon';
import {
  jwtToken,
  accountId,
  isAdmin,
  setJwtToken,
  setUserState,
  setListMachines,
} from '@/stores/user';
import { formatDate, formatDateRelative } from '@/lib/datetime';
import {
  IAccountUpsert,
  IMachineUpsert,
  IEntity,
  IMachineAssociation,
  ILoggedRequestParsed,
  ILoggedRequestRaw,
  ILoggedEventRaw,
  ILoggedEventParsed,
  IPage,
  IDataPoint,
} from '@/lib/interfaces';
/* eslint-disable-next-line import/no-cycle */
import { parseDataLoggedRequests, parseLoggedEvent } from '@/lib/data';

const headers = () => ({ Authorization: `Bearer ${jwtToken.value}` });

const formatTimestampsListEntities = (data: IEntity[]): IEntity[] => data.map((d: IEntity) => ({
  ...d,
  created_at_human: formatDate(d.created_at),
  updated_at_human: formatDate(d.updated_at),
  last_login_at_human: formatDateRelative(d.last_login_at),
}));

const formatErrorMessage = (err: AxiosError) => ` (Error ${err?.response?.status || 'err'})${
    err?.response?.data ? `\n${err.response.data}` : ''
}`;

// -------------------------------------------------------------------------------------------------
// Macchine

export const getListMachines = (): Promise<IEntity[]> => axios
  .get('/metadata/machines/list', { headers: headers() })
  .then((data: AxiosResponse<IEntity[]>) => {
    useToast().success('Machine list download completed');
    return formatTimestampsListEntities(data.data);
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the machine list${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

export const createNewMachine = (data: IMachineUpsert): Promise<void> => axios
  .post('/metadata/machines/create', data, { headers: headers() })
  .then(() => {
    useToast().success('Machine created correctly');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the creation of the machine${formatErrorMessage(
        err,
      )}`,
    );
  });

export const updateMachine = (data: IMachineUpsert): Promise<void> => axios
  .post('/metadata/machines/update', data, { headers: headers() })
  .then(() => {
    useToast().success('Machine updated successfully');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the update of the machine${formatErrorMessage(
        err,
      )}`,
    );
  });

// -------------------------------------------------------------------------------------------------
// Account

export const getListAccounts = (): Promise<void | IEntity[]> => axios
  .get('/metadata/accounts/list', { headers: headers() })
  .then((data: AxiosResponse<IEntity[]>) => {
    useToast().success('Account list download completed');
    return formatTimestampsListEntities(data.data);
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the account list${formatErrorMessage(
        err,
      )}`,
    );
  });

export const createNewAccount = (data: IAccountUpsert): Promise<void> => axios
  .post('/metadata/accounts/create', data, { headers: headers() })
  .then(() => {
    useToast().success('Account created correctly');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the creation of the account${formatErrorMessage(
        err,
      )}`,
    );
  });

export const updateAccount = (data: IAccountUpsert): Promise<void> => axios
  .post('/metadata/accounts/update', data, { headers: headers() })
  .then(() => {
    useToast().success('Account updated successfully');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the update of the account${formatErrorMessage(
        err,
      )}`,
    );
  });

// -------------------------------------------------------------------------------------------------
// Associazioni macchine

export const getListMachinesAssociated = (
  _accountId: string,
): Promise<Array<string>> => axios
  .get(`/metadata/association/list/${_accountId}`, { headers: headers() })
  .then((data) => data.data)
  .catch((err) => {
    useToast().error(
      `An error occurred during downloading the list of machines associated${formatErrorMessage(
        err,
      )}`,
    );
  });

export const createMachineAssociation = (
  data: Record<string, string>,
): Promise<void> => axios
  .post('/metadata/association/create', data, { headers: headers() })
  .then(() => {
    useToast().success('Machine association created correctly');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the creation of the machine association${formatErrorMessage(
        err,
      )}`,
    );
  });

export const removeMachineAssociation = (
  data: IMachineAssociation,
): Promise<void> => axios
  .post('/metadata/association/remove', data, { headers: headers() })
  .then(() => {
    useToast().success('Machine association removed successfully');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the deletion of the machine association${formatErrorMessage(
        err,
      )}`,
    );
  });

// -------------------------------------------------------------------------------------------------
// Dati

export const getDataPoint = (
  table: string,
  machineId: string,
  key: string,
  idMeasurement = '',
  getFullData = false,
): Promise<any | IDataPoint> => {
  if (
    includes(
      ['extradata', 'description', 'role', 'last_contact_at', 'last_login_at'],
      table,
    )
  ) {
    return axios
      .get(`/metadata/${table}/get?machineId=${machineId}`, {
        headers: headers(),
      })
      .then((data) => (key === '' ? data.data : get(data.data, key)))
      .catch((err) => {
        useToast().error(
          `An error occurred during downloading of some data${formatErrorMessage(
            err,
          )}`,
        );
        return {};
      });
  }

  return axios
    .get(
      `/data/${table}/get?machineId=${machineId}&key=${key}&id_measurement=${idMeasurement}`,
      { headers: headers() },
    )
    .then((data) => {
      if (data.status === 204) {
        return {};
      }

      if (table === 'json') {
        if (getFullData) {
          const d = data.data;
          d.value = JSON.parse(data.data.value || '{}');
          return d;
        }

        return JSON.parse(data.data.value || '{}');
      }

      return data.data.value;
    })
    .catch((err) => {
      useToast().error(
        `An error occurred during downloading of some data${formatErrorMessage(
          err,
        )}`,
      );
      return -1;
    });
};

export const writeDataPoint = (
  table: string,
  machineId: string,
  data: any,
): Promise<void> => axios
  .post(`/data/${table}/new?machineId=${machineId}`, data, {
    headers: headers(),
  })
  .then(() => {
    useToast().success('Data inserted correctly');
  })
  .catch((err) => {
    useToast().error(
      `An error occurred during the uploading of some data${formatErrorMessage(
        err,
      )}`,
    );
  });

export const queryDataPointsByKeyPart = (
  table: string,
  machineId: string,
  keyPart: string,
  count: number,
  getFullData = false,
): Promise<any[] | IDataPoint[]> => axios
  .get(
    `/data/${table}/query/key_part?machineId=${machineId}&keyPart=${keyPart}&count=${count}`,
    { headers: headers() },
  )
  .then((data) => {
    if (data.status === 204) {
      return [];
    }

    const listDocs: IDataPoint[] = data.data;
    let result: IDataPoint[];

    if (table === 'json') {
      result = listDocs.map((d) => {
        // eslint-disable-next-line no-param-reassign
        d.value = JSON.parse((d.value as string) || '{}');
        return d;
      });
    } else {
      result = listDocs;
    }

    if (getFullData) {
      return result;
    }

    return result.map((d) => d.value);
  });
// .catch((err) => {
//   useToast().error(`An error occurred
// during the querying of some data${formatErrorMessage(err)}`);
//   return [];
// })

/* ---------------------------------------------------------------------------------------------- */
/* Login e logout */

export const doLogin = async (
  authData: Record<string, string | boolean>,
): Promise<boolean> => {
  try {
    if (
      authData.username === ''
      || authData.password === ''
      || authData.projectId === ''
    ) {
      useToast().error('Some values are missing');
      return false;
    }

    const loginReponse = await axios.post('/auth/login', authData);
    setJwtToken(loginReponse.data);
    useToast().success('Login successful!');

    const accountAssociationsResponse = await axios.get(
      `/metadata/association/list/${accountId.value}`,
      {
        headers: headers(),
      },
    );

    if (accountAssociationsResponse.data.length) {
      setListMachines(accountAssociationsResponse.data);
    }

    if (isAdmin && accountAssociationsResponse.data.length === 0) {
      const machinesList = await getListMachines();
      const serialsList = machinesList.map((machine) => (machine.id_machine ? machine.id_machine : ''));
      setListMachines(serialsList);
    }

    return true;
  } catch (err: any) {
    setListMachines([]);
    useToast().error(
      `An error occurred during the login${formatErrorMessage(err)}`,
    );
    return false;
  }
};

export const refreshToken = (): void => {
  axios
    .get('/auth/refresh', { headers: headers() })
    .then((resp: AxiosResponse<string>) => {
      setJwtToken(resp.data);
    });
};

export const doLogout = (): void => {
  setUserState(null);
  useToast().success('Logout successful!');
};

/* ---------------------------------------------------------------------------------------------- */
/* Strumenti sviluppatore */

export const getLoggedRequests = (
  offset: number,
  limit: number,
  errors: number,
  method: string,
  typeEntity: string,
  idEntity: string,
): Promise<ILoggedRequestParsed[]> => axios
  .get('/developer/loggedrequests', {
    headers: headers(),
    params: {
      offset,
      limit,
      errors,
      method,
      type_entity: typeEntity,
      id_entity: idEntity,
    },
  })
  .then((data: AxiosResponse<ILoggedRequestRaw[]>) => (
    data.data.map((d) => parseDataLoggedRequests(d))
  ))
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the logged requests${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

interface ILoggedRequestSummaryRawItem {
  timestamp: string;
  count: number;
  countAccounts: number;
  countMachines: number;
  countErrors: number;
  distinctAccounts: number;
  distinctMachines: number;
}

export interface ILoggedRequestDaySummaryItem
  extends ILoggedRequestSummaryRawItem {
  daysAgo: number;
  weeksAgo: number;
  dayOfWeek: string;
  dayOfWeekNum: number;
  isCurrentWeek: boolean;
}

export interface ILoggedRequestMinuteSummaryItem
  extends ILoggedRequestSummaryRawItem {
  daysAgo: number;
}

export const getLoggedRequestsDaySummary = (
  since: string,
): Promise<ILoggedRequestDaySummaryItem[]> => axios
  .get('/developer/loggedrequests/summary', {
    headers: headers(),
    params: {
      granularity: 'day',
      since,
    },
  })
  .then((data: AxiosResponse<ILoggedRequestSummaryRawItem[]>) => data.data.map((d) => {
    const item: ILoggedRequestDaySummaryItem = {
      ...d,
      dayOfWeek: '',
      dayOfWeekNum: -1,
      weeksAgo: -1,
      daysAgo: -1,
      isCurrentWeek: false,
    };

    item.daysAgo = Math.floor(
      (+DateTime.now().toFormat('X')
            - +DateTime.fromISO(d.timestamp).toFormat('X'))
            / (3600 * 24),
    );
    item.weeksAgo = Math.floor(
      (item.daysAgo + 7 - +DateTime.now().toFormat('c')) / 7,
    );
    item.dayOfWeek = DateTime.fromISO(d.timestamp).toFormat('ccc');
    item.dayOfWeekNum = +DateTime.fromISO(d.timestamp).toFormat('c');
    item.isCurrentWeek = item.daysAgo <= 6
          && +DateTime.fromISO(d.timestamp).toFormat('c')
            <= +DateTime.now().toFormat('c');

    return item;
  }))
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the logged requests${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

export const getLoggedRequestsMinuteSummary = (
  since: string,
  granularity = 'day',
): Promise<ILoggedRequestMinuteSummaryItem[]> => axios
  .get('/developer/loggedrequests/summary', {
    headers: headers(),
    params: {
      granularity,
      since,
    },
  })
  .then((data: AxiosResponse<ILoggedRequestSummaryRawItem[]>) => data.data.map((d) => {
    const item = {
      ...d,
      daysAgo: -1,
    } as ILoggedRequestMinuteSummaryItem;

    item.daysAgo = Math.floor(
      (+DateTime.now()
        .set({ hour: 0, minute: 0, second: 0 })
        .toFormat('X')
            - +DateTime.fromISO(d.timestamp)
              .set({ hour: 0, minute: 0, second: 0 })
              .toFormat('X'))
            / (3600 * 24),
    );

    return item;
  }))
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the logged requests${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

interface ILoggedEventsSummaryRawItem {
  timestamp: string;
  countAccountLoginsOk: number;
  countAccountLoginsFailed: number;
  countMachinesLoginsOk: number;
  countMachinesLoginsFailed: number;
}

export interface ILoggedEventsDaySummaryItem
  extends ILoggedEventsSummaryRawItem {
  daysAgo: number;
  weeksAgo: number;
  dayOfWeek: string;
  dayOfWeekNum: number;
  isCurrentWeek: boolean;
}

export const getLoggedEventsDaySummary = (
  since: string,
): Promise<ILoggedEventsDaySummaryItem[]> => axios
  .get('/developer/loggedevents/summary', {
    headers: headers(),
    params: {
      granularity: 'day',
      since,
    },
  })
  .then((data: AxiosResponse<ILoggedEventsSummaryRawItem[]>) => data.data.map((d) => {
    const item: ILoggedEventsDaySummaryItem = {
      ...d,
      dayOfWeek: '',
      dayOfWeekNum: -1,
      weeksAgo: -1,
      daysAgo: -1,
      isCurrentWeek: false,
    };

    item.daysAgo = Math.floor(
      (+DateTime.now().toFormat('X')
            - +DateTime.fromISO(d.timestamp).toFormat('X'))
            / (3600 * 24),
    );
    item.weeksAgo = Math.floor(
      (item.daysAgo + 7 - +DateTime.now().toFormat('c')) / 7,
    );
    item.dayOfWeek = DateTime.fromISO(d.timestamp).toFormat('ccc');
    item.dayOfWeekNum = +DateTime.fromISO(d.timestamp).toFormat('c');
    item.isCurrentWeek = item.daysAgo <= 6
          && +DateTime.fromISO(d.timestamp).toFormat('c')
            <= +DateTime.now().toFormat('c');

    return item;
  }))
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the logged requests${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

export const getLoggedEvents = (
  offset: number,
  limit: number,
  errors: number,
  typeEntity: string,
  idEntity: string,
): Promise<ILoggedEventParsed[]> => axios
  .get('/developer/loggedevents', {
    headers: headers(),
    params: {
      offset,
      limit,
      errors,
      type_entity: typeEntity,
      id_entity: idEntity,
    },
  })
  .then((data: AxiosResponse<ILoggedEventRaw[]>) => data.data.map((d) => parseLoggedEvent(d)))
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the logged events${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

/* ---------------------------------------------------------------------------------------------- */
/* Pagine */

export const getPageCode = (pageId: string): Promise<string> => axios
  .get('/page/get', {
    headers: headers(),
    params: {
      pageId,
    },
  })
  .then((data: AxiosResponse<IPage>) => data.data.code)
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of the page${formatErrorMessage(
        err,
      )}`,
    );
    return '';
  });

export const getListPages = (): Promise<string[]> => axios
  .get('/page/list', {
    headers: headers(),
  })
  .then((data: AxiosResponse<string[]>) => data.data)
  .catch((err) => {
    useToast().error(
      `An error occurred during the download of pages list${formatErrorMessage(
        err,
      )}`,
    );
    return [];
  });

export const upsertPage = (page: IPage): void => {
  axios
    .post('/page/upsert', page, { headers: headers() })
    .then(() => {
      useToast().success('The page was uploaded correctly');
    })
    .catch((err) => {
      useToast().error(
        `An error occurred during the upload of the page${formatErrorMessage(
          err,
        )}`,
      );
    });
};
