
import { defineComponent } from 'vue';
import { filter, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { IChartDiscLinValue, ICharTimeLinValue } from '@/lib/interfaces';
import ChartDisc2LinWidget from '@/components/widgets/charts/ChartDisc2LinWidget.vue';
import ChartTime2LinWidget from '@/components/widgets/charts/ChartTime2LinWidget.vue';
import {
  getLoggedEventsDaySummary, getLoggedRequestsDaySummary, getLoggedRequestsMinuteSummary,
} from '@/lib/network';
import Button from '@/components/ui/Button.vue';
import StatCard from '@/components/ui/StatCard.vue';

export default defineComponent({
  name: 'DeveloperHome',
  components: {
    Button,
    StatCard,
    ChartDisc2LinWidget,
    ChartTime2LinWidget,
  },
  data() {
    return {
      countAccountsToday: 0,
      countRequestsToday: 0,
      countErrorsToday: 0,
      countLoginsToday: 0,

      reqDayRequestsDataAccounts: [] as IChartDiscLinValue[],
      reqDayRequestsDataErrors: [] as IChartDiscLinValue[],

      reqMinuteRequestsDataAccounts: [] as ICharTimeLinValue[],
      reqMinuteRequestsDataErrors: [] as ICharTimeLinValue[],

      reqDayLoginsDataAccounts: [] as IChartDiscLinValue[],
      reqDayLoginsFailedDataAccounts: [] as IChartDiscLinValue[],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getLoggedRequestsDaySummary(DateTime.now().toISODate())
        .then((data) => {
          this.countRequestsToday = data[0].countAccounts;
          this.countAccountsToday = data[0].distinctAccounts;
          this.countErrorsToday = data[0].countErrors;
        });

      getLoggedEventsDaySummary(DateTime.now().toISODate())
        .then((data) => {
          this.countLoginsToday = data[0].countAccountLoginsOk;
        });

      getLoggedRequestsDaySummary(DateTime.now().minus({ weeks: 2 }).toISODate())
        .then((data) => {
          const reqData = sortBy(
            filter(data, (d) => d.weeksAgo === 0 || d.weeksAgo === 1),
            (d) => d.dayOfWeekNum,
          );

          this.reqDayRequestsDataAccounts = reqData.map((d) => ({
            x0: d.dayOfWeekNum,
            x: d.dayOfWeek,
            y: d.count,
            g: d.weeksAgo,
          } as IChartDiscLinValue));

          this.reqDayRequestsDataErrors = reqData.map((d) => ({
            x0: d.dayOfWeekNum,
            x: d.dayOfWeek,
            y: d.countErrors,
            g: 2 + d.weeksAgo,
          } as IChartDiscLinValue));
        });

      getLoggedRequestsMinuteSummary(
        DateTime.now().minus({ days: 2 }).set({ hour: 0, minute: 0, second: 0 }).toISO(),
        'minute',
      )
        .then((data) => {
          const reqData = sortBy(
            filter(data, (d) => d.daysAgo === 0 || d.daysAgo === 1),
            (d) => d.timestamp,
          );

          this.reqMinuteRequestsDataAccounts = reqData.map((d) => ({
            x: +DateTime.fromISO(d.timestamp).set({ year: 0, month: 0, day: 0 }).toFormat('x'),
            y: d.countAccounts,
            g: d.daysAgo,
          } as ICharTimeLinValue));

          this.reqMinuteRequestsDataErrors = reqData.map((d) => ({
            x: +DateTime.fromISO(d.timestamp).set({ year: 0, month: 0, day: 0 }).toFormat('x'),
            y: d.countErrors,
            g: 2 + d.daysAgo,
          } as ICharTimeLinValue));
        });

      getLoggedEventsDaySummary(DateTime.now().minus({ weeks: 2 }).toISODate())
        .then((data) => {
          const reqData = sortBy(data, (d) => d.dayOfWeekNum);
          const tmpList1 : IChartDiscLinValue[] = [];
          const tmpList2 : IChartDiscLinValue[] = [];

          reqData.forEach((d) => {
            if (d.weeksAgo === 0 || d.weeksAgo === 1) {
              tmpList1.push({
                x0: d.dayOfWeekNum,
                x: d.dayOfWeek,
                y: d.countAccountLoginsOk,
                g: d.weeksAgo === 0 ? 0 : 1,
              });

              tmpList2.push({
                x0: d.dayOfWeekNum,
                x: d.dayOfWeek,
                y: d.countAccountLoginsFailed,
                g: d.weeksAgo === 0 ? 2 : 3,
              });
            }
          });

          this.reqDayLoginsDataAccounts = tmpList1;
          this.reqDayLoginsFailedDataAccounts = tmpList2;
        });
    },
  },
});
