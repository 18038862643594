
import { defineComponent } from 'vue';
import NavBar from '@/components/NavBar.vue';
import SideBar from '@/components/SideBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    checkScreenWidth() {
      if (!this.isOpen) return; // if sidebar is closed, don't open it on resize
      this.isOpen = window.innerWidth > 800;
    },
  },
});
