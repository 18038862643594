import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import Toast from 'vue-toastification';
import Harlem, { HarlemPlugin } from '@harlem/core';
import createDevtoolsPlugin from '@harlem/plugin-devtools';
import createStoragePlugin from '@harlem/plugin-storage';
import { setUserState } from '@/stores/user';
import messages from '@/lib/traduzioni';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './index.scss';
import './style2.scss';
import 'vue-toastification/dist/index.css';

// axios.defaults.baseURL = 'http://164.92.204.204:10001';
// axios.defaults.baseURL = 'http://backend.seleniaitalia.it:10001';
axios.defaults.baseURL = 'https://backend.seleniaitalia.it:10008';

const plugins : Array<HarlemPlugin> = [
  createDevtoolsPlugin({ label: 'State' }),
  createStoragePlugin('*', { type: 'local', prefix: 'dataingestion', sync: true }),
];

/*
 * Configuro la traduzione del sito
 * Per adesso sono presenti le lingue inglese e italiano
 */
type MessageSchema = typeof messages.it;

const i18n = createI18n<[MessageSchema], 'en-US' | 'it-IT'>({
  locale: 'it-IT',
  fallbackLocale: 'it-IT',
  messages: {
    'it-IT': messages.it,
    'en-US': messages.en,
  },
});

createApp(App)
  .use(router)
  .use(Harlem, { plugins })
  .use(Toast, {
    position: 'bottom-right',
    transition: 'Vue-Toastification__fade',
    timeout: 1500,
    closeOnClick: true,
    showCloseButtonOnHover: true,
    icon: false,
  })
  .use(i18n)
  .mount('#app');

// Carico lo stato degli store, se presenti
const storedState = localStorage.getItem('dataingestion:user');

if (storedState != null) {
  setUserState(JSON.parse(storedState));
}
