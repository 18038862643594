
import { isLoggedIn, isAdmin, username } from '@/stores/user';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import Button from '@/components/ui/Button.vue';
import { formatDate } from '@/lib/datetime';
import TagWidget from '@/components/widgets/TagWidget.vue';
import { fillDataConfigTemplate, IConfigTemplate } from '@/lib/data';
import { send } from '@/lib/smtp';
import { writeDataPoint, queryDataPointsByKeyPart } from '@/lib/network';
import { IRicaricaEffettuata } from '@/lib/interfaces';
import TableGeneralInfo from '@/components/tables-machine/TableGeneralInfo.vue';
import TableTemperatures from '@/components/tables-machine/TableTemperatures.vue';
import TableManipoli from '@/components/tables-machine/TableManipoli.vue';
import TableErrori from '@/components/tables-machine/TableErrori.vue';
import TableRicaricheEffettuate from '@/components/tables-machine/TableRicaricheEffettuate.vue';

// TODO: TB0116 NON HA IL DICT TAG, PERCHÉ?
interface IRicarica {
  controllo: string;
  utente: string;
  ore_ricarica: string;
}

interface IimpMacchina {
  controllo: string;
  blocco: string;
  is_full: string;
  agg_software: string;
  cancella_ore: string;
  ore_ricarica: string;
}

interface IContactForm {
  nome: string;
  cognome: string;
  email: string;
  cellulare: string;
  descrizione: string;
}

export default defineComponent({
  name: 'Machine',
  components: {
    Button,
    TagWidget,
    TableGeneralInfo,
    TableTemperatures,
    TableManipoli,
    TableErrori,
    TableRicaricheEffettuate,
  },
  data() {
    return {
      tipoTagConfig: {
        width: 1,
        pathData: 'type',
        dictTags: {
          TB: 'T-Body',
          TF: 'T-Face',
          LT: 'L-Tonic',
          CT: 'C-Tonic',
          XT: 'X-Tone',
          FB: 'FaceBody',
          BP: 'BodyPlus',
          LTP: 'L-Tonic Plus',
          XP: 'X-Press',
        },
        dictClasses: {
          TB: 'bg-light rounded-md text-black font-bold',
          TF: 'bg-light rounded-md text-black font-bold',
          LT: 'bg-dark rounded-md text-white font-bold',
          CT: 'bg-dark rounded-md text-white font-bold',
          XT: 'bg-dark rounded-md text-white font-bold',
          FB: 'bg-dark rounded-md text-white font-bold',
          BP: 'bg-light rounded-md text-black font-bold',
          LTP: 'bg-dark rounded-md text-white font-bold',
          XP: 'bg-dark rounded-md text-white font-bold',
        },
      },

      username,

      listaRicaricheEffettuate: [] as IRicaricaEffettuata[],

      loadingFinished: false,
      dbData: {} as any,
      isAdmin,

      ricarica: {
        ore_ricarica: '',
        controllo: '200',
        utente: '',
      } as IRicarica,

      impMacchina: {
        controllo: '200',
        blocco: '',
        is_full: '',
        agg_software: '',
        cancella_ore: '',
        ore_ricarica: '0',
      } as IimpMacchina,

      statoModaleHelp: {
        isOpen: false as boolean,
        serialeMacchina: {} as string,
      },

      contactForm: {} as IContactForm,
    };
  },

  beforeCreate() {
    if (!isLoggedIn) {
      this.$router.push({ name: 'home' });
    }
  },

  methods: {
    refreshPage() {
      this.listaRicaricheEffettuate = [];

      queryDataPointsByKeyPart(
        'json',
        this.$route.params.machineId as string,
        'imp_macchina',
        100,
        true,
      ).then((data) => {
        data.forEach((d) => {
          const ricaricaEffettuata: IRicaricaEffettuata = d.value;
          ricaricaEffettuata.dataRicarica = formatDate(d.timestamp);

          if (ricaricaEffettuata?.ore_ricarica !== '0') {
            this.listaRicaricheEffettuate.push(ricaricaEffettuata);
          }
        });
      });

      const machineDataTemplate: IConfigTemplate = {
        description: (s: string) => ({
          table: 'description',
          machineId: s,
          key: '',
        }),
        heartbeat: (s: string) => ({
          table: 'json',
          machineId: s,
          key: 'heartbeat',
        }),
        lastLogin: (s: string) => ({
          table: 'last_login_at',
          machineId: s,
          key: '',
        }),
        type: (s: string) => ({
          table: 'extradata',
          machineId: s,
          key: 'tipo',
        }),
      };

      if (this.$route.params.machineId !== undefined) {
        fillDataConfigTemplate(
          this.$route.params.machineId as string,
          machineDataTemplate,
        ).then((values) => {
          this.dbData = values;
          this.loadingFinished = true;
        });
      }
    },
    sendEmail() {
      // Rimuovere il return una volta che sono stati impostati i valori corretti (rimuovere anche
      // eslint-disable poco sotto). Usare le credenziali cifrate con il token perché altrimenti
      // se lasciate qui sono visibili all'esterno da chiunque.
      const config = {
        Host: '',
        Username: '',
        Password: '',
        To: 'assistenzatecnica@seleniaitalia.it',
        From: 'Ale',
        Subject: 'Prova invio automatico',
        Body: 'Messaggio inviato dal form sul sito :)',
      };

      send(config)
        .then(() => useToast().success('Richiesta inviata correttamente!'))
        .catch(() => useToast().error("Si è verificato un errore durante l'invio!"));
    },
    openModalHelp() {
      this.statoModaleHelp.isOpen = true;
      this.statoModaleHelp.serialeMacchina = 'boh';
    },

    inizializzaMacchina() {
      // Manda il json "imp_macchina" vuoto, in modo che quando la macchina lo chiede ci sia.

      writeDataPoint('json', this.$route.params.machineId as string, {
        key: 'imp_macchina',
        data: this.impMacchina,
      });
    },

    salvaRicarica() {
      this.ricarica.utente = username.value;

      writeDataPoint('json', this.$route.params.machineId as string, {
        key: 'imp_macchina',
        data: this.ricarica,
      });
    },
  },
  mounted() {
    this.loadingFinished = false;
    this.refreshPage();
    this.impMacchina.blocco = '0';
    this.impMacchina.agg_software = '0';
    this.impMacchina.is_full = '0';
    this.impMacchina.cancella_ore = '0';
  },
  watch: {
    '$route.params.machineId': function () {
      this.refreshPage();
    },
  },
  computed: {
    tipoMacchina() {
      const seriale = this.$route.params.machineId as string;

      if (seriale.startsWith('TB')) return 'TB';
      if (seriale.startsWith('TF')) return 'TF';
      if (seriale.startsWith('FB')) return 'FB';
      if (seriale.startsWith('LT')) return 'LT';
      if (seriale.startsWith('CT')) return 'CT';
      if (seriale.startsWith('XT')) return 'XT';
      if (seriale.startsWith('BP')) return 'BP';
      if (seriale.startsWith('XP')) return 'XP';
      if (seriale.startsWith('LTP')) return 'LTP';
      return '';
    },
  },
});
