
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import {
  includes, find, map, sortBy, reverse, filter,
} from 'lodash';
import { role } from '@/stores/user';
import { IEntity } from '@/lib/interfaces';
import {
  getListAccounts, createNewAccount,
  getListMachinesAssociated, createMachineAssociation,
  removeMachineAssociation, updateAccount,
} from '@/lib/network';
import Button from '@/components/ui/Button.vue';
import Table, { ITableConfig } from '@/components/ui/Table.vue';
import Modal from '@/components/ui/Modal.vue';
import StatCard from '@/components/ui/StatCard.vue';

export default defineComponent({
  name: 'ManageEntities',
  components: {
    Button,
    Table,
    Modal,
    StatCard,
  },
  data() {
    return {
      role,

      listAccounts: [] as IEntity[],
      listAccountsFiltered: [] as IEntity[],

      countAccounts: 0,
      countAccountsSeenToday: 0,

      configTableAccounts: {
        columns: [
          { header: 'Username', path: 'username' },
          { header: 'Role', path: 'role' },
          { header: 'Description', path: 'description' },
          { header: 'Contact', path: 'contact' },
          { header: 'Created', path: 'created_at_human' },
          { header: 'Updated', path: 'updated_at_human' },
          { header: 'Last login', path: 'last_login_at_human' },
        ],
        actions: [
          { event: 'edit-account-data', icon: 'fas fa-edit' },
          { event: 'edit-account-association', icon: 'fas fa-clone' },
        ],
      } as ITableConfig,

      showModalUpsert: false,
      idEntityEdit: '',
      entityModalUpsert: '',
      functionModalUpsert: '',
      titleModalUpsert: '',
      upsertUsername: '',
      upsertPassword: '',
      upsertIdAccount: '',
      upsertDescription: '',
      upsertContact: '',
      upsertRoleAccount: 'user',
      upsertExtradata: '',

      showModalSearch: false,
      typeSearch: '',
      searchTerm: '',

      showModalAssociation: false,
      isDownloadingMachineAssociations: false,
      editAssociationMachineId: '',
      editAssociationAccountId: '',
      listMachinesAssociated: [] as Record<string, string>[],

      configTableAssociations: {
        columns: [
          { header: 'Id machine', path: 'machineId' },
        ],
        actions: [
          { event: 'remove-machine-association', icon: 'fas fa-minus-circle' },
        ],
      } as ITableConfig,
    };
  },
  watch: {
    idEntityEdit() {
      const entity : IEntity | undefined = find(
        this.listAccountsFiltered.length === 0 ? this.listAccounts : this.listAccountsFiltered,
        (d : IEntity) => d.id_account === this.idEntityEdit,
      );

      if (entity === undefined) {
        return;
      }

      this.upsertUsername = entity?.username;
      this.upsertPassword = '';
      this.upsertIdAccount = entity.id_account || '';
      this.upsertDescription = entity?.description;
      this.upsertContact = entity?.contact;
      this.upsertRoleAccount = entity?.role || '';
      this.upsertExtradata = entity?.extradata || '';
    },
  },
  mounted() {
    this.refreshListAccounts();
  },
  methods: {
    searchAccounts() {
      this.listAccountsFiltered = this.listAccounts.filter((d : IEntity) => includes(
        `${d.username} ${d.description} ${d.contact} ${d.role}`.toLowerCase(),
        this.searchTerm.toLowerCase(),
      ));
    },

    refreshListAccounts() : void {
      getListAccounts()
        .then((data) => {
          this.listAccounts = reverse(sortBy(data || [], (d) => d.last_login_at || '0000-00-00'));

          this.countAccounts = this.listAccounts.length;
          this.countAccountsSeenToday = filter(
            this.listAccounts,
            (d) => d.last_login_at >= DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toISO(),
          ).length;
        });
    },

    upsertAccount() : void {
      const upsertAccountData = {
        id_account: this.upsertIdAccount,
        username: this.upsertUsername,
        password: this.upsertPassword,
        description: this.upsertDescription,
        contact: this.upsertContact,
        role: this.upsertRoleAccount,
        extradata: this.upsertExtradata,
      };

      if (this.functionModalUpsert === 'create') {
        createNewAccount(upsertAccountData)
          .then(() => {
            this.refreshListAccounts();
            this.showModalUpsert = false;
          });
      }
      if (this.functionModalUpsert === 'edit') {
        updateAccount(upsertAccountData)
          .then(() => {
            this.refreshListAccounts();
            this.showModalUpsert = false;
          });
      }
    },

    resetModal() {
      this.idEntityEdit = '';
      this.upsertUsername = '';
      this.upsertPassword = '';
      this.upsertDescription = '';
      this.upsertContact = '';
      this.upsertRoleAccount = '';
      this.upsertExtradata = '';
      this.editAssociationMachineId = '';
      this.editAssociationAccountId = '';
      this.listMachinesAssociated = [];
    },
    openModalCreateAccount() : void {
      this.titleModalUpsert = 'Create a new account';
      this.functionModalUpsert = 'create';
      this.entityModalUpsert = 'account';
      this.resetModal();
      this.showModalUpsert = true;
    },
    openModalEditAccount() : void {
      this.titleModalUpsert = 'Edit an account';
      this.functionModalUpsert = 'edit';
      this.entityModalUpsert = 'account';
      this.resetModal();
      this.showModalUpsert = true;
    },

    openModalAssociation(accountId : string) {
      this.showModalAssociation = true;
      this.resetModal();
      this.editAssociationAccountId = accountId;
      this.getMachinesAssociations(accountId);
    },
    getMachinesAssociations(accountId : string) {
      this.isDownloadingMachineAssociations = true;

      getListMachinesAssociated(accountId)
        .then((data : string[]) => {
          this.listMachinesAssociated = map(data, (d) => ({ machineId: d }));
          this.isDownloadingMachineAssociations = false;
        })
        .catch(() => {
          this.isDownloadingMachineAssociations = false;
        });
    },
    addMachineAssociation() {
      const data = {
        id_account: this.editAssociationAccountId,
        id_machine: this.editAssociationMachineId,
      };

      createMachineAssociation(data)
        .then(() => {
          this.getMachinesAssociations(this.editAssociationAccountId);
        });
    },
    removeMachineAssociation(machineId : string) {
      const machineAssociation = {
        id_machine: machineId,
        id_account: this.editAssociationAccountId,
      };

      removeMachineAssociation(machineAssociation)
        .then(() => {
          this.getMachinesAssociations(this.editAssociationAccountId);
        });
    },
  },
});
