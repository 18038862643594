
import { defineComponent } from 'vue';
import { isLoggedIn, isAdmin } from '@/stores/user';

const SideBarItem = defineComponent({
  props: {
    icon: String,
    classes: String,
    showLabel: { type: Boolean, default: true },
  },
  template: `
    <div :to="to" class="navitem flex justify-evenly items-center space-x-3">
      <i :class="icon"></i>
      <div v-if="showLabel" class="flex-grow" :class="classes">
        <slot></slot>
      </div>
    </div>
  `,
});

const SideBarLink = defineComponent({
  props: {
    to: String,
    icon: String,
    classes: String,
    showLabel: { type: Boolean, default: true },
  },
  template: `
    <router-link :to="to" class="navitem flex justify-evenly items-center space-x-3">
      <i :class="icon"></i>
      <div v-if="showLabel" class="flex-grow" :class="classes">
        <slot></slot>
      </div>
    </router-link>
  `,
});

export default defineComponent({
  name: 'SideBar',
  components: {
    SideBarItem,
    SideBarLink,
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  data() {
    return {
      isCollapsed: false,

      isLoggedIn,
      isAdmin,

      section: '',

      version: process.env.VUE_APP_VERSION,
      timestamp: process.env.VUE_APP_BUILD_TIMESTAMP,
    };
  },
  methods: {
    toggleSection(section: string) : void {
      this.section = this.section === section ? '' : section;
    },
  },
});
