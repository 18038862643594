/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        if (!newWorker) {
          return;
        }
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New content is available; notify the user to refresh
              console.log('New content is available; please refresh.');
              // You may choose to notify the user in your application UI
            } else {
              // This is the initial service worker installation
              console.log('Content is cached for offline use.');
            }
          }
        });
      });
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updated() {
      // This is called when the user has refreshed to get the new content
      console.log('New content is available; please refresh.');
      // You may choose to notify the user in your application UI
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
