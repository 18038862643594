
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableListaAnalisi',
  components: {
    Table,
  },
  props: {
    listaAnalisiEffettuate: {
      type: Array,
      required: true,
    },
    tipoMacchina: {
      type: String,
      required: true,
    },
    openModalDettagliAnalisi: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getTableData() {
      if (!this.listaAnalisiEffettuate) return [];
      return this.listaAnalisiEffettuate;
    },
    getTableConfig() {
      let baseConfig;

      // TOTAL BODY
      if (this.tipoMacchina === 'TB') {
        baseConfig = {
          columns: [
            { header: 'Data', path: 'dataTrattamento' },
            { header: 'Peso', path: 'Peso' },
            { header: 'Acqua Tot', path: 'AcquaTot' },
          ],
          actions: [{ event: 'view-dettagli-analisi', icon: 'fas fa-eye' }],
        };
      }

      // TOTAL FACE | LIFTONIC
      if (this.tipoMacchina === 'TF' || this.tipoMacchina === 'LT') {
        baseConfig = {
          columns: [
            { header: 'Data', path: 'dataTrattamento' },
            { header: 'Fototipo', path: 'Fototipo' },
            { header: 'Acqua Tot', path: 'Acqua' },
          ],
          actions: [{ event: 'view-dettagli-analisi', icon: 'fas fa-eye' }],
        };
      }

      return baseConfig;
    },
  },
});
