
import { defineComponent } from 'vue';
import { includes } from 'lodash';
import {
  getDataPoint,
  queryDataPointsByKeyPart,
  writeDataPoint,
} from '@/lib/network';
import {
  ISchedaCliente,
  ISedutaEffettuata,
  IAnalisiEffettuata,
} from '@/lib/interfaces';
import { formatDate } from '@/lib/datetime';
import { fillDataConfigTemplate, IConfigTemplate } from '@/lib/data';
import Table from '@/components/ui/Table.vue';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import TableInfoCliente from '@/components/tables-cliente/TableInfoCliente.vue';
import TableListaSedute from '@/components/tables-cliente/TableListaSedute.vue';
import TableListaAnalisi from '@/components/tables-cliente/TableListaAnalisi.vue';
import AggiungiSedutaProgrammata from '@/components/utils/AggiungiSedutaProgrammata.vue';

interface ISedutaProgrammata {
  controllo: string;
  manip: string;
  durata: string;
  potenza: string;
  zona: string;
  modalitalavoro: string;
  genere: string;
  morfologia: string;
}

export default defineComponent({
  name: 'Customer',
  components: {
    Table,
    Button,
    Modal,
    TableInfoCliente,
    TableListaSedute,
    TableListaAnalisi,
    AggiungiSedutaProgrammata,
  },
  data() {
    return {
      includes,

      loadingFinished: false,

      schedaCliente: null as ISchedaCliente | null,
      listaSeduteEffettuate: [] as ISedutaEffettuata[],
      listaAnalisiEffettuate: [] as IAnalisiEffettuata[],

      sedutaProgrammata: {
        controllo: '200',
        manip: '',
        durata: '',
        potenza: '',
        zona: '',
        modalitalavoro: '',
        morfologia: '',
        genere: '',
      } as ISedutaProgrammata,

      statoModaleDettagliAnalisi: {
        isOpen: false as boolean,
        seduta: {} as IAnalisiEffettuata,
      },

      dbData: {} as any,
    };
  },
  methods: {
    refreshPage() {
      const machineDataTemplate: IConfigTemplate = {
        description: (s: string) => ({
          table: 'description',
          machineId: s,
          key: '',
        }),
        heartbeat: (s: string) => ({
          table: 'json',
          machineId: s,
          key: 'heartbeat',
        }),
        lastLogin: (s: string) => ({
          table: 'last_login_at',
          machineId: s,
          key: '',
        }),
        type: (s: string) => ({
          table: 'extradata',
          machineId: s,
          key: 'tipo',
        }),
      };

      if (this.$route.params.machineId !== undefined) {
        fillDataConfigTemplate(
          this.$route.params.machineId as string,
          machineDataTemplate,
        ).then((values) => {
          this.dbData = values;
          this.loadingFinished = true;
          console.log(values);
        });
      }

      // Scarico la scheda del cliente
      getDataPoint(
        'json',
        this.$route.params.machineId as string,
        'scheda_cliente',
        this.$route.params.customerId as string,
      ).then((data) => {
        this.schedaCliente = data;
      });

      // Scarico la lista delle sedute effettuate da questo cliente
      this.listaSeduteEffettuate = [];
      this.listaAnalisiEffettuate = [];

      queryDataPointsByKeyPart(
        'json',
        this.$route.params.machineId as string,
        `trattamento_${this.$route.params.customerId}`,
        100,
        true,
      ).then((data) => {
        data.forEach((d) => {
          const sedutaEffettuata: ISedutaEffettuata = d.value;

          /* Ivan: Qui e' possibile modificare i dati della seduta effettuata appena scaricata, ad
            esempio per abbellire i dati o renderli piu' user-friendly (tipo trasformare da
            morfologia 2 a morfologia Normopeso, giusto un esempio a caso). Oppure ad es aggiungere
            minuti nella scritta della durata */
          sedutaEffettuata.dataTrattamento = formatDate(d.timestamp);
          sedutaEffettuata.Durata = `${sedutaEffettuata.Durata || 0} minuti`;
          sedutaEffettuata.Morfologia = this.semplificaMorfologia(
            sedutaEffettuata.Morfologia!,
            sedutaEffettuata.Manipolo!,
          );
          sedutaEffettuata.Zona = this.semplificaZona(sedutaEffettuata.Zona!);
          /* sedutaEffettuata.Manipolo = this.modificaManipolo(sedutaEffettuata.Manipolo!); */

          this.listaSeduteEffettuate.push(sedutaEffettuata);
        });
      });

      queryDataPointsByKeyPart(
        'json',
        this.$route.params.machineId as string,
        `analisi_${this.$route.params.customerId}`,
        100,
        true,
      ).then((data) => {
        data.forEach((d) => {
          const analisiEffettuata: IAnalisiEffettuata = d.value;

          analisiEffettuata.dataTrattamento = formatDate(d.timestamp);
          analisiEffettuata.BMI = this.modificaBmi(analisiEffettuata.BMI!);
          // ritorno bmi round
          this.listaAnalisiEffettuate.push(analisiEffettuata);
        });
      });
    },

    modificaBmi(input: string): string {
      let buff = 0;
      buff = parseInt(input, 10);
      buff = Math.round(buff);
      return buff.toString();
    },

    semplificaMorfologia(input: string, manipp: string): string {
      const seriale = this.$route.params.machineId as string;
      if (
        seriale.startsWith('LT')
        || seriale.startsWith('TF')
        || (seriale.startsWith('FB') && manipp === 'ROSSO')
      ) return '';
      if (input === '0') return 'Longilinea';
      if (input === '1') return 'Normolinea';
      if (input === '2') return 'Brevilinea';

      return input;
    },

    semplificaZona(input: string): string {
      if (input === '0') return 'Gambe';
      if (input === '1') return 'Braccia';
      if (input === '2') return 'Petto';
      if (input === '3') return 'Dorso';
      if (input === '4') return 'Cosce';
      if (input === '5') return 'Glutei';
      if (input === '6') return 'Addome';
      if (input === '7') return 'Viso';

      return input;
    },

    salvaSedutaProgrammata(seduta: any) {
      writeDataPoint('json', this.$route.params.machineId as string, {
        key: `imp_trattamento${this.$route.params.customerId}`,
        id_measurement: this.$route.params.customerId,
        data: seduta,
      });
    },

    openModalDettagliAnalisi(seduta: IAnalisiEffettuata) {
      this.statoModaleDettagliAnalisi.isOpen = true;
      this.statoModaleDettagliAnalisi.seduta = seduta;
    },

    async fillFormTB() {
      // Fetch the PDF with form fields
      const formUrl = 'https://areaclienti.seleniaitalia.it/schedaDettaglioAnalisiTB.pdf';
      const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

      // Load a PDF with form fields
      const pdfDoc = await PDFDocument.load(formPdfBytes);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();

      // Get all fields in the PDF by their names
      const dataField = form.getTextField('data');
      const nameField = form.getTextField('nome');
      const surnameField = form.getTextField('cognome');
      const morfField = form.getTextField('morfologia');
      const bmiField = form.getTextField('bmi');
      const pesoField = form.getTextField('peso');
      const pesoFormaField = form.getTextField('pesoforma');
      const metBasaleField = form.getTextField('metbasale');
      const AcquaTotField = form.getTextField('acquatot');
      const AcquaIntraField = form.getTextField('acquaintra');
      const AcquaExtraField = form.getTextField('acquaextra');
      const RitIdricaField = form.getTextField('ritidrica');
      const MassaGrassaField = form.getTextField('massagrassa');
      const MassaMagraField = form.getTextField('massamagra');
      const MassaMuscolareField = form.getTextField('massamusc');
      const TonoMuscField = form.getTextField('tonomusc');
      const CelluliteField = form.getTextField('cellulite');
      const P1Field = form.getTextField('p1');
      const P2Field = form.getTextField('p2');
      const P3Field = form.getTextField('p3');
      const P4Field = form.getTextField('p4');
      const P5Field = form.getTextField('p5');

      if (this.statoModaleDettagliAnalisi.seduta.P1) {
        P1Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P1 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A1 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P2) {
        P2Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P2 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A2 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P3) {
        P3Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P3 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A3 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P4) {
        P4Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P4 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A4 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P5) {
        P5Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P5 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A5 ?? ''}`);
      }

      // Fill in the basic info fields
      dataField.setText(this.statoModaleDettagliAnalisi.seduta.dataTrattamento);
      nameField.setText(this.statoModaleDettagliAnalisi.seduta.nome);
      surnameField.setText(this.statoModaleDettagliAnalisi.seduta.cognome);
      morfField.setText(this.statoModaleDettagliAnalisi.seduta.Morfologia);
      bmiField.setText(this.statoModaleDettagliAnalisi.seduta.BMI);
      pesoField.setText(this.statoModaleDettagliAnalisi.seduta.Peso);
      pesoFormaField.setText(this.statoModaleDettagliAnalisi.seduta.PesoForma);
      metBasaleField.setText(this.statoModaleDettagliAnalisi.seduta.MetBasale);
      AcquaTotField.setText(this.statoModaleDettagliAnalisi.seduta.AcquaTot);
      AcquaIntraField.setText(
        this.statoModaleDettagliAnalisi.seduta.AcquaIntra,
      );
      AcquaExtraField.setText(
        this.statoModaleDettagliAnalisi.seduta.AcquaExtra,
      );
      RitIdricaField.setText(this.statoModaleDettagliAnalisi.seduta.Ritenzione);
      MassaGrassaField.setText(
        this.statoModaleDettagliAnalisi.seduta.MassaGrassa,
      );
      MassaMagraField.setText(
        this.statoModaleDettagliAnalisi.seduta.MassaMagra,
      );
      MassaMuscolareField.setText(
        this.statoModaleDettagliAnalisi.seduta.MassaMucolare,
      );
      TonoMuscField.setText(
        this.statoModaleDettagliAnalisi.seduta.TonoMuscolare,
      );
      CelluliteField.setText(this.statoModaleDettagliAnalisi.seduta.Cellulite);

      form.flatten();

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Set custom filename
      const fileName = `AnalisiCorpo_${
        this.statoModaleDettagliAnalisi.seduta.cognome
      }_${this.statoModaleDettagliAnalisi.seduta.dataTrattamento!}`;

      // Trigger the browser to download the PDF document
      download(pdfBytes, fileName, 'application/pdf');
    },

    async fillFormTF() {
      // Fetch the PDF with form fields
      const formUrl = 'https://areaclienti.seleniaitalia.it/schedaDettaglioAnalisiTF.pdf';
      const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

      // Load a PDF with form fields
      const pdfDoc = await PDFDocument.load(formPdfBytes);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();

      // Get all fields in the PDF by their names
      const dataField = form.getTextField('data');
      const nameField = form.getTextField('nome');
      const surnameField = form.getTextField('cognome');
      const fototipoField = form.getTextField('fototipo');
      const trattConsField = form.getTextField('trattConsigliati');
      const AcquaTotField = form.getTextField('acquatot');
      const AcquaIntraField = form.getTextField('acquaintra');
      const AcquaExtraField = form.getTextField('acquaextra');
      const DisidratazioneField = form.getTextField('disidratazione');
      const StratoCorneoField = form.getTextField('stratocorneo');
      const PhField = form.getTextField('ph');
      const SeboField = form.getTextField('sebo');
      const ElasticitaField = form.getTextField('elasticita');
      const SensibilitaField = form.getTextField('sensibilita');
      const TonicitaField = form.getTextField('tonicita');
      const P1Field = form.getTextField('p1');
      const P2Field = form.getTextField('p2');
      const P3Field = form.getTextField('p3');
      const P4Field = form.getTextField('p4');
      const P5Field = form.getTextField('p5');

      if (this.statoModaleDettagliAnalisi.seduta.P1) {
        P1Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P1 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A1 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P2) {
        P2Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P2 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A2 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P3) {
        P3Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P3 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A3 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P4) {
        P4Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P4 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A4 ?? ''}`);
      }
      if (this.statoModaleDettagliAnalisi.seduta.P5) {
        P5Field.setText(`${this.statoModaleDettagliAnalisi.seduta.P5 ?? ''}
${this.statoModaleDettagliAnalisi.seduta.A5 ?? ''}`);
      }

      // Fill in the basic info fields
      dataField.setText(this.statoModaleDettagliAnalisi.seduta.dataTrattamento);
      nameField.setText(this.statoModaleDettagliAnalisi.seduta.nome);
      surnameField.setText(this.statoModaleDettagliAnalisi.seduta.cognome);
      fototipoField.setText(this.statoModaleDettagliAnalisi.seduta.Fototipo);
      trattConsField.setText(
        this.statoModaleDettagliAnalisi.seduta.N_Trattamenti,
      );
      AcquaTotField.setText(this.statoModaleDettagliAnalisi.seduta.Acqua);
      AcquaIntraField.setText(
        this.statoModaleDettagliAnalisi.seduta.Acqua_intra,
      );
      AcquaExtraField.setText(
        this.statoModaleDettagliAnalisi.seduta.Acqua_extra,
      );
      DisidratazioneField.setText(
        this.statoModaleDettagliAnalisi.seduta.Disidratazione_sup,
      );
      StratoCorneoField.setText(
        this.statoModaleDettagliAnalisi.seduta.StatoCorneo,
      );
      PhField.setText(this.statoModaleDettagliAnalisi.seduta.PH);
      SeboField.setText(this.statoModaleDettagliAnalisi.seduta.Sebo);
      ElasticitaField.setText(
        this.statoModaleDettagliAnalisi.seduta.Elasticita,
      );
      SensibilitaField.setText(
        this.statoModaleDettagliAnalisi.seduta.Sensibilita,
      );
      TonicitaField.setText(this.statoModaleDettagliAnalisi.seduta.Tonicita);

      form.flatten();

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Set custom filename
      const fileName = `Analisi_${
        this.statoModaleDettagliAnalisi.seduta.cognome
      }${this.statoModaleDettagliAnalisi.seduta.nome}_${this
        .statoModaleDettagliAnalisi.seduta.dataTrattamento!}`;

      // Trigger the browser to download the PDF document
      download(pdfBytes, fileName, 'application/pdf');
    },
  },
  computed: {
    tipoMacchina() {
      const seriale = this.$route.params.machineId as string;

      if (seriale.startsWith('TB')) return 'TB';
      if (seriale.startsWith('TF')) return 'TF';
      if (seriale.startsWith('FB')) return 'FB';
      if (seriale.startsWith('LT')) return 'LT';
      if (seriale.startsWith('CT')) return 'CT';
      if (seriale.startsWith('XT')) return 'XT';
      if (seriale.startsWith('BP')) return 'BP';
      if (seriale.startsWith('XP')) return 'XP';
      if (seriale.startsWith('BT')) return 'BT';
      if (seriale.startsWith('ST')) return 'ST';

      return '';
    },
  },
  watch: {
    'sedutaProgrammata.manip': function () {
      if (this.sedutaProgrammata.manip === 'verde') {
        this.sedutaProgrammata.potenza = '0';
      }
      if (
        this.tipoMacchina === 'CT'
        && this.sedutaProgrammata.manip === 'verde'
      ) {
        this.sedutaProgrammata.zona = '';
      }
      this.sedutaProgrammata.modalitalavoro = '';
    },
  },
  mounted() {
    this.refreshPage();

    this.sedutaProgrammata.manip = '';
    this.sedutaProgrammata.durata = '5';
    this.sedutaProgrammata.zona = '';
    this.sedutaProgrammata.potenza = '0';
    this.sedutaProgrammata.modalitalavoro = '';
    if ((this.$route.params.machineId as string).startsWith('TF')) {
      this.sedutaProgrammata.zona = 'viso';
    }
  },
});
