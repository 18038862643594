
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import {
  includes, find, sortBy, reverse, filter,
} from 'lodash';
import { isLoggedIn } from '@/stores/user';
import Button from '@/components/ui/Button.vue';
import Table, { ITableConfig } from '@/components/ui/Table.vue';
import { queryDataPointsByKeyPart, getListMachines } from '@/lib/network';
import { IEntity } from '@/lib/interfaces';

export default defineComponent({
  name: 'azioniMacchine',
  components: {
    Table,
    Button,
  },
  data() {
    return {
      listMachinesFiltered: [] as IEntity[],
      listMachines: [] as IEntity[],
      showModalSearch: false,
      typeSearch: '',
      searchTerm: '',

      countMachines: 0,
      countMachinesSeenToday: 0,

      configTableMachines: {
        columns: [
          { header: 'Seriale', path: 'id_machine' },
          { header: 'V. Software', path: 'versionesw' },
          { header: 'FULL ?', path: 'is_full' },
        ],
        actions: [
          { event: 'aggiorna', icon: 'fas fa-upload' },
          { event: 'blocca', icon: 'fas fa-block' },
        ],
      } as ITableConfig,
    };
  },

  beforeCreate() {
    if (!isLoggedIn) {
      this.$router.push({ name: 'home' });
    }
  },

  methods: {
    searchMachines() {
      this.listMachinesFiltered = this.listMachines.filter(
        (d: IEntity) => includes(`${d.id_machine} ${d.username}
          ${d.description} ${d.contact}`.toLowerCase(), this.searchTerm.toLowerCase()),
      );
    },

    refreshPage() {
      getListMachines()
        .then((data) => {
          this.listMachines = reverse(sortBy(data || [], (d) => d.last_login_at || '0000-00-00'));

          this.countMachines = this.listMachines.length;
          this.countMachinesSeenToday = filter(
            this.listMachines,
            (d) => d.last_login_at >= DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toISO(),
          ).length;
        });
    },
  },
  mounted() {
    this.refreshPage();
  },
});
