
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableManipoli',
  components: {
    Table,
  },
  props: {
    machineData: {
      type: Object,
      required: true,
    },
    tipoMacchina: {
      type: String,
      required: true,
    },
  },
  methods: {
    getTableData() {
      if (!this.machineData) return [];
      if (!this.tipoMacchina) return [];

      let baseData: Record<string, unknown>[] = [];

      // TOTAL BODY
      if (this.tipoMacchina === 'TB') {
        baseData = [
          {
            label: 'Manipolo Giallo',
            valueOre: this.machineData.heartbeat?.ore_giallo,
            valueSeriale: this.machineData.heartbeat?.serial_manip11,
            valueFreq: this.machineData.heartbeat?.frequenza3,
          },
          {
            label: 'Manipolo Rosso',
            valueOre: this.machineData.heartbeat?.ore_rosso,
            valueSeriale: this.machineData.heartbeat?.serial_manip10,
            valueFreq: this.machineData.heartbeat?.frequenza1,
          },
          {
            label: 'Manipolo Dermoporazione',
            valueOre: this.machineData.heartbeat?.ore_dermopor,
            valueSeriale: '⃠',
            valueFreq: '⃠',
          },
          {
            label: 'Manipolo Vacuum',
            valueOre: this.machineData.heartbeat?.ore_vacuum,
            valueSeriale: '⃠',
            valueFreq: '⃠',
          },
        ];
      }

      // CELLUTONIC
      if (this.tipoMacchina === 'CT') {
        baseData = [
          {
            label: 'Manipolo Giallo',
            valueOre: this.machineData.heartbeat?.ore_giallo,
            valueSeriale: this.machineData.heartbeat?.serial_manip11,
            valueFreq: this.machineData.heartbeat?.frequenzaOD,
          },
          {
            label: 'Manipolo Verde',
            valueOre: this.machineData.heartbeat?.ore_dermopor,
            valueSeriale: this.machineData.heartbeat?.serial_manip10,
            valueFreq: this.machineData.heartbeat?.frequenzaDP,
          },
        ];
      }

      // LIFTONIC & LIFTONIC PLUS
      if (this.tipoMacchina === 'LT' || this.tipoMacchina === 'LTP') {
        baseData = [
          {
            label: 'Manipolo Rosso',
            valueOre: this.machineData.heartbeat?.ore_rosso,
            valueSeriale: this.machineData.heartbeat?.serial_manip11 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaOD34,
          },
          {
            label: 'Manipolo Verde',
            valueOre: this.machineData.heartbeat?.ore_dermopor,
            valueSeriale: this.machineData.heartbeat?.serial_manip10 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaDP,
          },
        ];
      }

      // FACE & BODY
      if (this.tipoMacchina === 'FB') {
        baseData = [
          {
            label: 'Manipolo Giallo',
            valueOre: this.machineData.heartbeat?.ore_giallo,
            valueSeriale: this.machineData.heartbeat?.serial_manip11 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaOD,
          },
          {
            label: 'Manipolo Rosso',
            valueOre: this.machineData.heartbeat?.ore_rosso,
            valueSeriale: this.machineData.heartbeat?.serial_manip10 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaDP,
          },
        ];
      }

      // TOTAL FACE
      if (this.tipoMacchina === 'TF') {
        baseData = [
          {
            label: 'Onda dinamica',
            valueOre: this.machineData.heartbeat?.ore_rosso,
            valueSeriale: this.machineData.heartbeat?.serial_manip10 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaDP,
          },
          {
            label: 'Dermoabrasione',
            valueOre: this.machineData.heartbeat?.ore_dermoabr,
            valueSeriale: this.machineData.heartbeat?.seriale_dermoabr || 0,
            valueFreq: '⃠',
          },
          {
            label: 'Dermoregenia',
            valueOre: this.machineData.heartbeat?.ore_vacuum,
            valueSeriale: '⃠',
            valueFreq: '⃠',
          },
          {
            label: 'Dermoporazione',
            valueOre: this.machineData.heartbeat?.ore_dermopor,
            valueSeriale: this.machineData.heartbeat?.serial_manip11 || 0,
            valueFreq: this.machineData.heartbeat?.frequenzaDP,
          },
        ];
      }

      // DIMAGRELLE X-TONE
      if (this.tipoMacchina === 'XT') {
        baseData = [
          {
            label: 'Body Shaping',
            valueOre: this.machineData.heartbeat?.ore_bodyshape,
            valueSeriale: '⃠',
            valueFreq: '⃠',
          },
          {
            label: 'Localizzato',
            valueOre: this.machineData.heartbeat?.ore_local,
            valueSeriale: '⃠',
            valueFreq: '⃠',
          },
        ];
      }

      return baseData;
    },
    getTableConfig() {
      const header = this.tipoMacchina === 'XT' ? 'Trattamento' : 'Manipolo';
      const baseConfig = {
        columns: [
          {
            header,
            path: 'label',
          },
          {
            header: 'Ore',
            path: 'valueOre',
          },
          {
            header: 'MHz',
            path: 'valueFreq',
          },
          {
            header: 'Seriale',
            path: 'valueSeriale',
          },
        ],
      };

      if (this.tipoMacchina === 'XT') {
        // remove Seriale and MHz
        baseConfig.columns.splice(2, 1);
        baseConfig.columns.splice(2, 1);
      }
      return baseConfig;
    },
  },
});
