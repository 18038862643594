import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-8 flex-grow-0 w-full lg:w-6/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Table, {
      data: _ctx.getTableData(),
      config: _ctx.getTableConfig()
    }, null, 8, ["data", "config"])
  ]))
}