const createCORSRequest = function (e: any, n: any) {
  var t = new XMLHttpRequest;
  // return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t
  t.open(e, n, !0)
  return t;
};

const ajax = function (e: any, n: any) {
  var t = createCORSRequest("GET", e);
  t.onload = function () { var e = t.responseText; null != n && n(e) };
  t.send();
};

const ajaxPost = function (e: any, n: any, t: any) {
  var a = createCORSRequest("POST", e);
  a.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  a.onload = function () { var e = a.responseText; null != t && t(e) };
  a.send(n);
};

export const send = function (a: any) {
  return new Promise(
    function (n, e) {
      a.nocache = Math.floor(1e6 * Math.random() + 1);
      a.Action = "Send";
      var t = JSON.stringify(a);
      ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e: any) { n(e) })
    }
  )
};
