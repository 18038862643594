import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my-card flex-col justify-center" }
const _hoisted_2 = { class: "my-card--header" }
const _hoisted_3 = {
  key: 0,
  class: "my-title"
}
const _hoisted_4 = {
  key: 1,
  class: "my-subtitle"
}
const _hoisted_5 = { class: "my-card--body" }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.subtitle.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        id: _ctx.id,
        class: "flex justify-center w-full"
      }, null, 8, _hoisted_6)
    ])
  ]))
}