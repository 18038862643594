
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpaceWidget',
  components: {
  },
  props: {
    showEditTools: { default: false },

    config: { default: () => ({ width: 1, classes: '' }), type: Object },
    data: { default: () => ({}), type: Object },
    dbData: { default: () => ({}), type: Object },
  },
  data() {
    return {
    };
  },
  computed: {
    configClasses() {
      return `md:col-span-${this.config.width} ${this.config.classes}`;
    },
  },
});
