
import { defineComponent } from 'vue';
import { doLogin } from '@/lib/network';
import { isLoggedIn } from '@/stores/user';

export default defineComponent({
  name: 'Home',
  components: {},
  data() {
    return {
      username: '' as string,
      password: '' as string,

      errorInfo: '' as string,
    };
  },
  beforeCreate() {
    if (isLoggedIn) {
      this.$router.push({ name: 'MachinesList' });
    }
  },
  methods: {
    async login() {
      const authData = {
        username: this.username,
        password: this.password,
        projectId: 'webapp1',
        type: 'account',
        isLongLived: true,
      };

      const loggedIn = await doLogin(authData);

      // Se il login e' avvenuto con successo si viene redirezionati alla pagina della lista
      // macchine altrimenti no
      if (loggedIn) {
        this.$router.push({ name: 'MachinesList' });
      } else {
        this.errorInfo = 'Ritenta il login';
      }
    },
  },
});
