
import { defineComponent } from 'vue';
import { toPairs, filter } from 'lodash';
import { getLoggedEvents } from '@/lib/network';
import { ILoggedEventParsed, ILoggedRequestParsed } from '@/lib/interfaces';
import Button from '@/components/ui/Button.vue';
import Table, { ITableConfig, ICell, IRow } from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'LoggedEvents',
  components: {
    Button,
    Table,
  },
  data() {
    return {
      toPairs,
      filter,

      page: 0,
      onlyErrors: 0,
      typeEntity: 'machine',
      idEntity: '',

      listEvents: [] as ILoggedEventParsed[],

      configTable: {
        columns: [
          {
            header: 'Timestamp',
            path: 'timestamp_human',
            size: 3,
          },
          {
            header: 'Seriale',
            path: 'id_entity',
            size: 3,
          },
          {
            header: 'Tipo di evento',
            path: 'name',
            size: 2,
          },
          {
            header: 'Risultato',
            path: 'result',
            size: 2,
            modifier: (d: ICell, dd: IRow) => (dd.isError ? 'text-red-500 font-bold bg-red-200 bg-opacity-50' : 'text-green-700'),
          },
        ],
        actions: [
        ],
      } as ITableConfig,
    };
  },
  mounted() {
    this.refreshData(0);
  },
  methods: {
    refreshData(page: number) : void {
      if (page === -1) {
        return;
      }
      if (this.listEvents.length === 0 && this.page > 0 && page > this.page) {
        return;
      }

      this.page = Math.max(page, 0);

      getLoggedEvents(this.page * 25, 25, this.onlyErrors, this.typeEntity, this.idEntity)
        .then((data) => {
          this.listEvents = data;
        });
    },
  },
});
