
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableInfoCliente',
  components: {
    Table,
  },
  props: {
    schedaCliente: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTableData() {
      if (!this.schedaCliente) return [];
      const baseData = [
        { label: 'Nome', value: this.schedaCliente?.nome },
        { label: 'Cognome', value: this.schedaCliente?.cognome },
        { label: 'Data di Nascita', value: this.schedaCliente?.datanascita },
        { label: 'Telefono', value: this.schedaCliente?.tel },
        { label: 'Email', value: this.schedaCliente?.email },
      ];
      return baseData;
    },
    getTableConfig() {
      return {
        columns: [
          { header: 'Valore', path: 'label' },
          { header: '', path: 'value', classes: 'capitalize' },
        ],
      };
    },
  },
});
