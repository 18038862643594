import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-card--body flex flex-row items-center gap-x-5" }
const _hoisted_2 = {
  class: "font-bold",
  style: {"font-size":"75px"}
}
const _hoisted_3 = {
  class: "",
  style: {"font-size":"25px"}
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["my-card", _ctx.configClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.cardData), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", { innerHTML: _ctx.markdownHtml }, null, 8, _hoisted_4)
      ])
    ])
  ], 2))
}