
import { defineComponent } from 'vue';
import {
  isLoggedIn, username, role, projectId,
} from '@/stores/user';
import { doLogout } from '@/lib/network';

const NavBarItem = defineComponent({
  props: {
    to: String,
  },
  template: `
    <router-link :to="to" class="navitem">
      <slot></slot>
    </router-link>
  `,
});

export default defineComponent({
  name: 'NavBar',
  components: {
    NavBarItem,
  },
  data() {
    return {
      isCollapsed: false,

      isLoggedIn,
      username,
      role,
      projectId,
      doLogout,
    };
  },
  mounted() {
    // Importante, il pulsante per collassare la navbar è presente solo sotto un
    //  certo breakpoint (md:, 768px)
    // Noi facciamo in modo che se la larghezza dello schermo e' minore
    //  di x la nabvar parta gia' collassata.
    // Se x > 768 e' possibile che la navbar sia collassata, ma contemporaneamente
    //  il pulsante per renderla visibile sia nascosto, quindi sarebbe impossibile
    //  utilizzare la navbar. Quindi x deve essere < del breakpoint del pulsante
    this.isCollapsed = (document.getElementById('app')?.offsetWidth || 1000) < 700;
  },
});
