
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import {
  includes, find, sortBy, reverse, filter,
} from 'lodash';
import { role } from '@/stores/user';
import { IEntity } from '@/lib/interfaces';
import {
  getListMachines, createNewMachine, updateMachine,
} from '@/lib/network';
import Button from '@/components/ui/Button.vue';
import Table, { ITableConfig } from '@/components/ui/Table.vue';
import Modal from '@/components/ui/Modal.vue';
import StatCard from '@/components/ui/StatCard.vue';

export default defineComponent({
  name: 'ManageEntities',
  components: {
    Button,
    Table,
    Modal,
    StatCard,
  },
  data() {
    return {
      role,

      listMachines: [] as IEntity[],
      listMachinesFiltered: [] as IEntity[],

      countMachines: 0,
      countMachinesSeenToday: 0,

      configTableMachines: {
        columns: [
          { header: 'Id', path: 'id_machine' },
          { header: 'Username', path: 'username' },
          { header: 'Description', path: 'description' },
          { header: 'Contact', path: 'contact' },
          { header: 'Created', path: 'created_at_human' },
          { header: 'Updated', path: 'updated_at_human' },
          { header: 'Last login', path: 'last_login_at_human' },
        ],
        actions: [
          { event: 'edit-machine-data', icon: 'fas fa-edit' },
        ],
      } as ITableConfig,

      showModalUpsert: false,
      idEntityEdit: '',
      entityModalUpsert: '',
      functionModalUpsert: '',
      titleModalUpsert: '',
      upsertUsername: '',
      upsertPassword: '',
      upsertIdMachine: '',
      upsertDescription: '',
      upsertContact: '',
      upsertRoleAccount: 'user',
      upsertExtradata: '',

      showModalSearch: false,
      typeSearch: '',
      searchTerm: '',
    };
  },
  watch: {
    idEntityEdit() {
      const entity : IEntity | undefined = find(
        this.listMachinesFiltered.length === 0 ? this.listMachines : this.listMachinesFiltered,
        (d : IEntity) => d.id_machine === this.idEntityEdit,
      );

      if (entity === undefined) {
        return;
      }

      this.upsertUsername = entity?.username;
      this.upsertPassword = '';
      this.upsertIdMachine = entity.id_machine || '';
      this.upsertDescription = entity?.description;
      this.upsertContact = entity?.contact;
      this.upsertRoleAccount = entity?.role || '';
      this.upsertExtradata = entity?.extradata || '';
    },
  },
  mounted() {
    this.refreshListMachines();
  },
  methods: {
    searchMachines() {
      this.listMachinesFiltered = this.listMachines.filter((d : IEntity) => includes(
        `${d.id_machine} ${d.username} ${d.description} ${d.contact}`.toLowerCase(),
        this.searchTerm.toLowerCase(),
      ));
    },

    refreshListMachines() : void {
      getListMachines()
        .then((data) => {
          this.listMachines = reverse(sortBy(data || [], (d) => d.last_login_at || '0000-00-00'));

          this.countMachines = this.listMachines.length;
          this.countMachinesSeenToday = filter(
            this.listMachines,
            (d) => d.last_login_at >= DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toISO(),
          ).length;
        });
    },

    upsertMachine() : void {
      const upsertMachineData = {
        id_machine: this.upsertIdMachine,
        username: this.upsertUsername,
        password: this.upsertPassword,
        description: this.upsertDescription,
        contact: this.upsertContact,
        extradata: this.upsertExtradata,
      };

      if (this.functionModalUpsert === 'create') {
        createNewMachine(upsertMachineData)
          .then(() => {
            this.refreshListMachines();
            this.showModalUpsert = false;
          });
      }
      if (this.functionModalUpsert === 'edit') {
        updateMachine(upsertMachineData)
          .then(() => {
            this.refreshListMachines();
            this.showModalUpsert = false;
          });
      }
    },

    resetModal() {
      this.idEntityEdit = '';
      this.upsertUsername = '';
      this.upsertPassword = '';
      this.upsertIdMachine = '';
      this.upsertDescription = '';
      this.upsertContact = '';
      this.upsertRoleAccount = '';
      this.upsertExtradata = '';
    },
    openModalCreateMachine() : void {
      this.titleModalUpsert = 'Create a new machine';
      this.functionModalUpsert = 'create';
      this.entityModalUpsert = 'machine';
      this.resetModal();
      this.showModalUpsert = true;
    },
    openModalEditMachine() : void {
      this.titleModalUpsert = 'Edit a machine';
      this.functionModalUpsert = 'edit';
      this.entityModalUpsert = 'machine';
      this.resetModal();
      this.showModalUpsert = true;
    },
  },
});
