
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableInfoGenerali',
  components: {
    Table,
  },
  props: {
    machineData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTableData() {
      const baseData = [
        {
          label: 'Codice Seriale',
          value: this.machineData?.heartbeat?.seriale,
        },
        {
          label: 'Versione Software',
          value: this.machineData?.heartbeat?.versionesw,
        },
        {
          label: 'Codice Assistenza',
          value: this.machineData?.heartbeat?.anydeskid,
        },
        {
          label: 'Ore totali',
          value: this.machineData?.heartbeat?.ore_tot,
        },
      ];

      if (this.machineData?.heartbeat?.is_full === 0) {
        baseData.push({
          label: 'Tempo Rimasto',
          value: `${this.machineData?.heartbeat?.ore_rest}h ${this.machineData?.heartbeat?.min_rest}m`,
        });
      }

      return baseData;
    },
    getTableConfig() {
      return {
        columns: [
          {
            header: 'Informazioni',
            path: 'label',
          },
          {
            header: 'Dati',
            path: 'value',
          },
        ],
      };
    },
  },
});
