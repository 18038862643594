
import Table from '@/components/ui/Table.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TableTemperatures',
  components: {
    Table,
  },
  props: {
    machineData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTableData() {
      const baseData = [
        {
          label: 'Scheda RF',
          value: this.machineData?.heartbeat?.Temp_RF,
        },
        {
          label: 'Scheda US',
          value: this.machineData?.heartbeat?.Temp_US,
        },
      ];
      return baseData;
    },
    getTableConfig() {
      return {
        columns: [
          { header: 'Scheda', path: 'label' },
          { header: 'C°', path: 'value' },
        ],
      };
    },
  },
});
