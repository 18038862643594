
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    isShowing: { type: Boolean, default: false },
    classes: { type: String, default: '' },
  },
});
