
import { defineComponent } from 'vue';
import { get } from 'lodash';
import { listMachines } from '@/stores/user';
import Button from '@/components/ui/Button.vue';
import Image from '@/components/ui/Image.vue';
import {
  fillArrayDataConfigTemplate,
  IConfigTemplate,
  IFilledConfig,
} from '@/lib/data';

export default defineComponent({
  name: 'MachinesList',
  components: {
    Button,
    Image,
  },

  data() {
    return {
      get,

      tipoTagConfig: {
        width: 1,
        pathData: 'type',
        dictTags: {
          TB: 'T-Body',
          TF: 'T-Face',
          LT: 'L-Tonic',
          CT: 'C-Tonic',
          XT: 'X-Tone',
          FB: 'FaceBody',
          BP: 'BodyPlus',
          LTP: 'L-Tonic Plus',
          XP: 'X-Press',
        },
        dictClasses: {
          TB: 'bg-light rounded-md text-black font-bold',
          TF: 'bg-light rounded-md text-black font-bold',
          LT: 'bg-dark rounded-md text-white font-bold',
          CT: 'bg-dark rounded-md text-white font-bold',
          XT: 'bg-dark rounded-md text-white font-bold',
          FB: 'bg-dark rounded-md text-white font-bold',
          BP: 'bg-light rounded-md text-black font-bold',
          LTP: 'bg-dark rounded-md text-white font-bold',
          XP: 'bg-dark rounded-md text-white font-bold',
        },
      },

      statoTagConfig: {
        width: 1,
        pathData: 'status',
        dictTags: {
          active: 'ACTIVE',
          idle: 'IDLE',
          poweroff: 'OFF',
          error: 'ERROR',
        },
        dictClasses: {
          active: 'text-green-600 font-bold bg-green-100 rounded-md',
          idle: 'text-yellow-600 font-bold bg-yellow-100 rounded-md',
          poweroff: 'text-black',
          error: 'text-red-800 font-bold bg-red-100 rounded-md',
        },
      },

      dbData: [] as IFilledConfig[],
    };
  },
  mounted() {
    setTimeout(() => {
      this.refreshListMachines();
    }, 500);
  },
  methods: {
    refreshListMachines(): void {
      const machineDataTemplate: IConfigTemplate = {
        codice: (s: string) => s,
        type: (s: string) => ({
          table: 'extradata',
          machineId: s,
          key: 'tipo',
        }),
        status: (s: string) => ({
          table: 'text',
          machineId: s,
          key: 'status',
        }),
        description: (s: string) => ({
          table: 'description',
          machineId: s,
          key: '',
        }),
        lastPoweron: (s: string) => ({
          table: 'last_login_at',
          machineId: s,
          key: '',
        }),
        lastContact: (s: string) => ({
          table: 'last_contact_at',
          machineId: s,
          key: '',
        }),
      };
      fillArrayDataConfigTemplate(listMachines.value, machineDataTemplate).then(
        (values) => {
          this.dbData = values;
        },
      );
    },
  },
});
