import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "my-section" }
const _hoisted_3 = { class: "my-title uppercase" }
const _hoisted_4 = { class: "my-buttons my-card--body" }
const _hoisted_5 = { class: "border-solid border-2 rounded-lg flex-grow-0 w-full lg:w-10/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("reportErrori.titolo")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          class: "is-secondary",
          icon: "fas fa-sync",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshPage()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.aggiorna')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Table, {
          data: _ctx.listaMacchineConErrori,
          config: {columns: [
          { header: 'Matricola', path: 'seriale' },
          { header: 'Errori', path: 'nrErrori' },
          ], actions: [
            { event: 'open', icon: 'fas fa-eye' }
          ]},
          onOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'Machine', params: { machineId: $event.seriale } })))
        }, null, 8, ["data"])
      ])
    ])
  ]))
}