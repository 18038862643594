
import { defineComponent } from 'vue';
import TagWidget from '@/components/widgets/TagWidget.vue';

export default defineComponent({
  name: 'Image',
  components: {
    TagWidget,
  },
  props: {
    seriale: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    dbData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pathImageBasedOnSeriale: '' as string,
    };
  },
  mounted() {
    if (this.seriale.startsWith('TB') || this.seriale.startsWith('TF')) {
      this.pathImageBasedOnSeriale = '/img/grandeCOMP.png';
      return;
    }
    if (
      this.seriale.startsWith('XT')
      || this.seriale.startsWith('FB')
      || this.seriale.startsWith('CT')
      || this.seriale.startsWith('LT')
    ) {
      this.pathImageBasedOnSeriale = '/img/piccolaCOMP.png';
      return;
    }
    if (this.seriale.startsWith('XP')) {
      this.pathImageBasedOnSeriale = '/img/xpress.png';
      return;
    }
    this.pathImageBasedOnSeriale = '';
  },
});
