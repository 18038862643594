import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { isTokenValid } from '@/stores/user';
import Home from '../views/Home.vue';
import ManageAccounts from '../views/Manage/ManageAccounts.vue';
import ManageMachines from '../views/Manage/ManageMachines.vue';
import Machine from '../views/Machine.vue';
import MachinesList from '../views/MachinesList.vue';
import ListaClienti from '../views/ListaClienti.vue';
import reportErrori from '../views/reportErrori.vue';
import azioniMacchine from '../views/azioniMacchine.vue';
import Cliente from '../views/Cliente.vue';
import DeveloperHome from '../views/Developer/DeveloperHome.vue';
import LoggedRequests from '../views/Developer/LoggedRequests.vue';
import LoggedEvents from '../views/Developer/LoggedEvents.vue';
import Documentation from '../views/Developer/Documentation.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/manage/accounts',
    name: 'ManageAccounts',
    component: ManageAccounts,
  },
  {
    path: '/manage/machines',
    name: 'ManageMachines',
    component: ManageMachines,
  },
  {
    path: '/macchina/:machineId',
    name: 'Machine',
    component: Machine,
  },
  {
    path: '/clienti/:machineId',
    name: 'CustomerList',
    component: ListaClienti,
  },
  {
    path: '/clienti/:machineId/:customerId',
    name: 'Customer',
    component: Cliente,
  },
  {
    path: '/machine-list',
    name: 'MachinesList',
    component: MachinesList,
  },
  {
    path: '/reportErrori',
    name: 'reportErrori',
    component: reportErrori,
  },
  {
    path: '/azioniMacchine',
    name: 'azioniMacchine',
    component: azioniMacchine,
  },
  {
    path: '/developer',
    name: 'DeveloperHome',
    component: DeveloperHome,
  },
  {
    path: '/developer/log',
    name: 'DeveloperLoggedRequests',
    component: LoggedRequests,
  },
  {
    path: '/developer/events',
    name: 'DeveloperLoggedEvents',
    component: LoggedEvents,
  },
  {
    path: '/developer/documentation',
    name: 'Documentation',
    component: Documentation,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // Prima di ogni cambio pagina controllo la scadenza del token jwt attualmente memorizzato
  // nel browser.
  // Se ancora e' valido e non scaduto il cambio pagina viene eseguito come richiesto.
  // Inoltre se sto andando alla pagina Home (cioe' quella del login) ci vado sempre, questo per
  // evitare loop infiniti di redirezione (cioe' il token e' scaduto e mi rimanda a Home, nel mentre
  // ci sto andando viene ricontrollato il tolen che e' ancora scaduto e mi rimanda a Home, e cosi'
  // via all'infinito).
  if (isTokenValid.value || to.name === 'Home') {
    return true;
  }

  // Se invece il token e' scaduto cambio la destinazione redirezionando l'utente
  // verso la pagina di login
  return '/';
});

export default router;
