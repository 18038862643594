
import { defineComponent } from 'vue';
import { get } from 'lodash';
import { useToast } from 'vue-toastification';
import { getDataPoint, queryDataPointsByKeyPart } from '@/lib/network';
import { ISchedaCliente } from '@/lib/interfaces';
import { formatDate } from '@/lib/datetime';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'ListaClienti',
  components: {
    Button,
  },
  data() {
    return {
      get,

      listaClienti: [] as ISchedaCliente[],
    };
  },
  methods: {
    refreshPage() {
      queryDataPointsByKeyPart('json', (this.$route.params.machineId as string),
        'scheda_cliente', 100, true)
        .then((data) => {
          useToast().success('Lista dei clienti scaricata correttamente!');

          this.listaClienti = [];
          data.forEach((d) => {
            // Estraggo la scheda vera e propria dal valore di IDataPoint
            const schedaCliente = d.value as ISchedaCliente;
            schedaCliente.idCliente = d.id_measurement;

            // Mi scarico la data dell'ultima seduta
            // Volendo visto che ormai abbiamo scaricato il dato dell'ultima seduta possiamo anche
            // inserire altre informazioni nella lista (ad es che zona, durata ecc)
            getDataPoint('json', (this.$route.params.machineId as string),
              `trattamento_${d.id_measurement}`, '', true)
              .then((dataPointSeduta) => {
                if (dataPointSeduta.timestamp === undefined) {
                  return;
                }

                // TODO Migliorare questo ciclo che funziona ma e' bruttissimo eventualmente
                //  trasformare listaClienti in un oggetto e referenziare direttamente la scheda
                //  tramite l'id paziente
                // Assegno al cliente la stringa con la data dell'ultimo trattamento
                this.listaClienti.forEach((dd, ii) => {
                  if (dd.idCliente === d.id_measurement) {
                    this.listaClienti[ii]
                      .dataUltimoTrattamento = formatDate(dataPointSeduta.timestamp);
                  }
                });
              });

            this.listaClienti.push(schedaCliente);
          });
        });
    },
  },
  mounted() {
    this.refreshPage();
  },
  watch: {
    '$route.params.machineId': function () {
      this.refreshPage();
    },
  },
});
