
import { defineComponent } from 'vue';
import { get } from 'lodash';
import Table, { IRow } from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableWidget',
  components: {
    Table,
  },
  props: {
    showEditTools: { default: false },
    config: { default: () => ({ width: 1, columns: [], tableConfig: {} }), type: Object },
    dbData: { type: Object, required: true },
  },
  data() {
    return {
    };
  },
  computed: {
    tableData() {
      // Se e' specificato pathData, come quasi sempre sara', i dati che vengono
      //  mandati al componente Table me li prendo nei dati usando quel percorso
      // Altrimenti ritorno semplicemente i dati in ingresso
      if (this.config.pathData) {
        return get(this.dbData, this.config.pathData, []);
      }
      return this.dbData;
    },
    configClasses() {
      return `md:col-span-${this.config.width}`;
    },
  },
});
