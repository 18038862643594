
import { defineComponent } from 'vue';
import { get } from 'lodash';
import marked from 'marked';

export default defineComponent({
  name: 'StatCardWidget',
  props: {
    showEditTools: { default: false },

    config: {
      default: () => ({
        width: 1,
        classes: '',
        markdownSource: () => '',
        pathData: '',
      }),
      type: Object,
    },
    dbData: { default: () => ({}), type: Object },
  },
  computed: {
    markdownHtml() {
      return marked(this.config.markdownSource(this.dbData) || '');
    },
    configClasses() {
      return `md:col-span-${this.config.width} ${this.config.classes || ''}`;
    },
    cardData() {
      // Se e' specificato pathData, come quasi sempre sara', i dati che vengono
      //  mandati al componente Table me li prendo nei dati usando quel percorso
      // Altrimenti ritorno semplicemente i dati in ingresso
      if (this.config.pathData) {
        return get(this.dbData, this.config.pathData, -1);
      }
      return this.dbData;
    },
  },
});
