
import { defineComponent } from 'vue';
import { get } from 'lodash';

export type ICell = any;

export type IRow = any;

interface IColumnConfig {
  header: string;
  path: string;
  size?: number;
  classes?: string;
  modifier?: (cell: ICell, row: IRow) => string;
}

interface IActionsConfig {
  event: string;
  icon: string;
}

export interface ITableConfig {
  columns: IColumnConfig[];
  actions?: IActionsConfig[];
}

export default defineComponent({
  name: 'Table',
  props: {
    config: {
      type: Object as () => ITableConfig,
      default: () => ({ columns: [] }),
    },
    data: { type: Array as () => IRow[], default: () => [] },
  },
  data() {
    return {
      get,
    };
  },
});
