import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container relative flex items-center justify-center", _ctx.configClasses])
  }, [
    _createVNode(_component_Table, {
      config: _ctx.config.tableConfig,
      data: _ctx.tableData
    }, null, 8, ["config", "data"])
  ], 2))
}