import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container relative flex items-center justify-center text-center", _ctx.configClasses])
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.to,
      innerHTML: _ctx.markdownHtml
    }, null, 8, ["to", "innerHTML"]),
    _renderSlot(_ctx.$slots, "editTools")
  ], 2))
}