
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableListaSedute',
  components: {
    Table,
  },
  props: {
    listaSeduteEffettuate: {
      type: Array,
      required: true,
    },
    tipoMacchina: {
      type: String,
      required: true,
    },
  },
  methods: {
    getTableData() {
      if (!this.listaSeduteEffettuate) return [];

      if (this.tipoMacchina === 'XP') {
        const listaWithoutErrors = this.listaSeduteEffettuate.filter(
          (seduta: any) => +seduta.Errore === 0,
        );
        return listaWithoutErrors;
      }
      return this.listaSeduteEffettuate;
    },
    getTableConfig() {
      let baseConfig = {
        columns: [
          { header: 'Data', path: 'dataTrattamento' },
          { header: 'Durata', path: 'Durata' },
          { header: 'Zona', path: 'Zona' },
          { header: 'Manipolo', path: 'Manipolo' },
          { header: 'Morfologia', path: 'Morfologia' },
        ],
      };
      if (this.tipoMacchina === 'XP') {
        baseConfig = {
          columns: [
            { header: 'Data', path: 'dataTrattamento' },
            { header: 'Durata', path: 'Durata' },
            { header: 'Programma', path: 'Programma' },
            { header: 'Tipo', path: 'TipoMass' },
            { header: 'Pressione', path: 'Pressione' },
            { header: 'Tempo tenuta', path: 'Tempo tenuta' },
            // { header: 'Morfologia', path: 'Morfologia'},
            // { header: 'Zona', path: 'Zona' },
          ],
        };
      }
      return baseConfig;
    },
  },
});
