import { createStore } from '@harlem/core';
import jwtDecode from 'jwt-decode';
import { IClaims, IState } from '@/lib/interfaces';
import { JWT_CLAIM_ROLE } from '@/lib/constants';

const STATE : IState = {
  isLoggedIn: false,
  jwtToken: '',
  expiration: 0,
  accountId: '',
  username: '',
  projectId: '',
  role: '',

  listMachines: [],
};

const { getter, mutation } = createStore<IState>('user', STATE);

export const isLoggedIn = getter('isLoggedIn', (state) => state.isLoggedIn);
export const expiration = getter('expiration', (state) => state.expiration);
export const jwtToken = getter('jwtToken', (state) => state.jwtToken);
export const accountId = getter('accountId', (state) => state.accountId);
export const username = getter('username', (state) => state.username);
export const projectId = getter('projectId', (state) => state.projectId);
export const role = getter('role', (state) => state.role);
export const listMachines = getter('listMachines', (state) => state.listMachines);
export const isAdmin = getter('isAdmin', (state) => state.role === 'owner' || state.role === 'admin');

export const setJwtToken = mutation<string>('setJwtToken', (state, payload) => {
  state.isLoggedIn = true;
  state.jwtToken = payload;

  const claims : IClaims = jwtDecode(payload);
  state.expiration = claims.exp;
  state.accountId = claims.id_account;
  state.username = claims.user;
  state.projectId = claims.id_project;
  state.role = claims[JWT_CLAIM_ROLE];
});
export const unsetJwtToken = mutation<void>('unsetJwtToken', (state) => {
  state.isLoggedIn = false;
  state.jwtToken = '';

  state.expiration = 0;
  state.accountId = '';
  state.username = '';
  state.projectId = '';
  state.role = '';
});
export const setListMachines = mutation<string[]>('setListMachines', (state, payload) => {
  state.listMachines = payload;
});
export const setUserState = mutation<IState | null>('setUserState', (state, payload) => {
  // In questo caso per settare tutto lo stato dell'utente mi basta settare il jwtToken
  //  e la listMachines
  // Sostanzialmente questa funzione viene chiamata quando si effettua il login e il logout
  if (payload !== null && payload.jwtToken !== '') {
    // Ramo chiamato quando si fa il login (in questo caso payload e' il contenuto del localstorage)
    // TODO aggiungere un controllo sulla expiration del token
    setJwtToken(payload.jwtToken);
    setListMachines(payload.listMachines);
  } else {
    // Ramo chiamato quando si fa il logout (in questo caso payload e' null)
    unsetJwtToken();
    setListMachines([]);
  }
});

/**
 * Controlla se il token che ho in memoria e' ancora valido, se non lo e' rimuovilo
 * @returns true se il token e' valido, false se e' scaduto
 */
export const isTokenValid = getter('isTokenValid', (state) => {
  if (Date.now() < state.expiration * 1000) {
    return true;
  }

  unsetJwtToken();
  return false;
});
