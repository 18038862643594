
import { defineComponent } from 'vue';
import { has, get } from 'lodash';
import marked from 'marked';
import { ITagWidgetConfig } from '@/lib/interfaces';

export default defineComponent({
  name: 'TagWidget',
  components: {
  },
  props: {
    showEditTools: { default: false },

    config: {
      default: () : ITagWidgetConfig => (
        {
          width: 1, dictClasses: {}, dictTags: {}, pathData: '',
        }
      ),
      type: Object,
    },
    data: { default: () => (() => ('')), type: Function },
    dbData: { default: () => ({}), type: Object },
  },
  computed: {
    // Calcolo i valori delle classi e della stringa da mostrare in base al dato
    //  che mi viene passato
    currentTagKey(): string {
      return get(this.dbData, this.config.pathData, '');
    },
    currentTagValue() {
      if (has(this.config.dictTags, this.currentTagKey)) {
        return this.config.dictTags[this.currentTagKey];
      }

      return '';
    },
    currentClasses() {
      if (has(this.config.dictTags, this.currentTagKey)) {
        return this.config.dictClasses[this.currentTagKey];
      }

      return '';
    },
    markdownHtml() {
      return marked(this.currentTagValue);
    },
    configClasses() {
      return `md:col-span-${this.config.width} ${this.currentClasses}`;
    },
  },
});
