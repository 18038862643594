import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container relative flex items-center justify-center text-center", _ctx.configClasses])
  }, [
    _createElementVNode("div", { innerHTML: _ctx.markdownHtml }, null, 8, _hoisted_1),
    _renderSlot(_ctx.$slots, "editTools")
  ], 2))
}