export const JWT_CLAIM_ROLE = 'account_role';

/**
 * Ogni elemento rappresenta un endpoint esposto dal server e contiene le informazioni per
 * determinare se una certa chiamata e' corretta o no
 * 0) Il primo elemento e' una espressione regolare che matcha l'url in questione
 * 1) Verbo HTTP accettato da questo endpoint
 * 2) Lista di parametri obbligatori della query
 * 3) Lista di parametri facoltativi della query
 */
export const LIST_REGEX_SERVER_PATHS = [
  /* Richieste di lettura e scrittura dati */
  [/^\/data\/numeric\/get[^/]*$/i, 'GET', ['key'], ['onlyValue', 'machineId']],
  [/^\/data\/text\/get[^/]*$/i, 'GET', ['key'], ['onlyValue', 'machineId']],
  [/^\/data\/json\/get[^/]*$/i, 'GET', ['key'], ['onlyValue', 'machineId', 'fields', 'separator', 'delimiter']],
  [/^\/data\/numeric\/new$/i, 'POST', [], []],
  [/^\/data\/text\/new$/i, 'POST', [], []],
  [/^\/data\/json\/new$/i, 'POST', [], []],
  /* Richieste della webapp */
  [/^\/metadata\/machines\/list$/i, 'GET', [], []],
  [/^\/metadata\/machines\/create$/i, 'POST', [], []],
  [/^\/metadata\/machines\/update$/i, 'POST', [], []],
  [/^\/metadata\/accounts\/list$/i, 'GET', [], []],
  [/^\/metadata\/accounts\/create$/i, 'POST', [], []],
  [/^\/metadata\/accounts\/update$/i, 'POST', [], []],
  [/^\/metadata\/association\/list\/[^/]*$/i, 'GET', [], []],
  [/^\/metadata\/association\/create$/i, 'POST', [], []],
  [/^\/metadata\/association\/remove$/i, 'POST', [], []],
  [/^\/metadata\/[a-zA-Z0-9_]*\/get$/i, 'GET', ['machineId'], []],
  [/^\/developer\/requests$/i, 'GET', [], ['offset', 'limit', 'errors', 'method']],
  [/^\/developer\/loggedevents$/i, 'GET', [], ['offset', 'limit', 'errors', 'type_entity', 'id_entity']],
  [/^\/developer\/loggedevents\/summary$/i, 'GET', [], ['granularity', 'since', 'to']],
  [/^\/page\/get$/i, 'GET', ['pageId'], []],
] as any[][];
