import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4bdeb70"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "my-table"
}
const _hoisted_2 = { class: "my-table--row my-table--header" }
const _hoisted_3 = {
  key: 0,
  class: "my-table--cell"
}
const _hoisted_4 = { class: "my-table--row" }
const _hoisted_5 = {
  class: "my-table--cell",
  style: {"flex-grow":"1"}
}
const _hoisted_6 = {
  key: 0,
  class: "italic"
}
const _hoisted_7 = {
  key: 1,
  class: "italic"
}
const _hoisted_8 = {
  key: 1,
  class: "my-table"
}
const _hoisted_9 = { class: "my-table--row my-table--header" }
const _hoisted_10 = {
  key: 0,
  class: "my-table--cell"
}
const _hoisted_11 = {
  key: 0,
  class: "my-table--cell"
}
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.config.columns.length === 0 || _ctx.data.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.columns, (col) => {
              return (_openBlock(), _createElementBlock("div", {
                key: col.path,
                class: "my-table--cell",
                style: _normalizeStyle(`flex-grow: ${col?.size || 1}`)
              }, [
                _createElementVNode("span", null, _toDisplayString(col.header), 1)
              ], 4))
            }), 128)),
            (_ctx.config.actions && _ctx.config.actions.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Actions"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.config.columns.length === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Tabella non configurata"))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, "Tabella vuota"))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.config.columns.length > 0 && _ctx.data.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.columns, (col) => {
              return (_openBlock(), _createElementBlock("div", {
                key: col.path,
                class: "my-table--cell",
                style: _normalizeStyle(`flex-grow: ${col?.size || 1}`)
              }, [
                _createElementVNode("span", null, _toDisplayString(col.header), 1)
              ], 4))
            }), 128)),
            (_ctx.config.actions && _ctx.config.actions.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Actions"))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "my-table--row"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.columns, (col) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: col.path,
                  class: _normalizeClass(["my-table--cell", `${col.modifier ? col.modifier(_ctx.get(row, col.path), row) : ''}
          ${col.classes ? col.classes : ''}`]),
                  style: _normalizeStyle(`flex-grow: ${col?.size || 1}`)
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.get(row, col.path)), 1)
                ], 6))
              }), 128)),
              (_ctx.config.actions && _ctx.config.actions.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.actions, (action, j) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: j,
                        class: "action-icon"
                      }, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.$emit(action.event, row, i))
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(action.icon)
                          }, null, 2)
                        ], 8, _hoisted_12)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}