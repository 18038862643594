
import { defineComponent } from 'vue';
import { filter, get } from 'lodash';
import { isLoggedIn } from '@/stores/user';
import Button from '@/components/ui/Button.vue';
import Table from '@/components/ui/Table.vue';
import { queryDataPointsByKeyPart, getListMachines } from '@/lib/network';
import { IMacchinaConErrori, IEntity } from '@/lib/interfaces';

export default defineComponent({
  name: 'ReportErrori',
  components: {
    Table,
    Button,
  },
  data() {
    return {
      listaMacchineConErrori: [] as IMacchinaConErrori[],
      listMachines: [] as IEntity[],
    };
  },

  beforeCreate() {
    if (!isLoggedIn) {
      this.$router.push({ name: 'home' });
    }
  },

  methods: {
    refreshPage() {
      this.listaMacchineConErrori = [];
      const PROP_ERRORITB = ['errori_rf', 'errori_us', 'err_OC_US', 'err_corr_US', 'errori_rosso',
        'errori_giallo'];
      const PROP_ERRORITF = ['errori_rf', 'errori_us', 'err_OC_US', 'err_corr_US'];
      getListMachines()
        .then((data) => {
          this.listMachines = data;
          this.listMachines.forEach((entity) => {
            if (entity.id_machine) {
              queryDataPointsByKeyPart('json', entity.id_machine, 'heartbeat', 1, true)
                .then((data2) => {
                  data2.forEach((d) => {
                    const macchina = d.value;
                    macchina.seriale = d.id_machine;
                    // total body e macchine che hanno entrambi manipoli con scheda (cellutonic)
                    if (macchina.seriale.startsWith('TB') || macchina.seriale.startsWith('CT')
                     || macchina.seriale.startsWith('BP')) {
                      macchina.nrErrori = filter(PROP_ERRORITB, (prop) => get(macchina, prop, '0') !== '0').length;
                    }
                    // total face e altre che non hanno i manipoli con scheda
                    if (macchina.seriale.startsWith('TF')) {
                      macchina.nrErrori = filter(PROP_ERRORITF, (prop) => get(macchina, prop, '0') !== '0').length;
                    }
                    if (macchina.nrErrori > 0) {
                      this.listaMacchineConErrori.push(macchina);
                    }
                  });
                });
            }
          });
        });
    },
  },
  mounted() {
    this.refreshPage();
  },
});
