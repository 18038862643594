
import Button from '@/components/ui/Button.vue';

export default {
  name: 'Aggiungi Seduta Programmata',
  components: {
    Button,
  },
  props: {
    tipoMacchina: {
      type: String,
      required: true,
    },
    salvaSedutaProgrammata: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      genereOptions: [
        { value: '0', label: 'Uomo' },
        { value: '1', label: 'Donna' },
      ],
      sedutaProgrammata: {
        controllo: '200',
        manip: null,
        modalitalavoro: null,
        zona: null,
        durata: null,
        potenza: null,
        morfologia: null,
        genere: null,
        trattamento: null,
        tenuta: null,
        pressione: null,
      },
      pressioneOptions: [
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
      ],
      tenutaOptions: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
      ],
      trattamentoXPOptions: [
        { value: '0', label: 'Drenaggio Gambe leggere' },
        { value: '1', label: 'Drenaggio Leggero' },
        { value: '2', label: 'Drenaggio Intenso' },
        { value: '3', label: 'Drenaggio Forte' },
        { value: '4', label: 'Drenaggio fisiologico' },
        { value: '5', label: 'Drenaggio Modellante' },
        { value: '6', label: 'Drenaggio Distensivo' },
        { value: '7', label: 'Drenaggio Riattivante' },
        { value: '8', label: 'cellulite compatta' },
        { value: '9', label: 'cellulite edematosa' },
        { value: '10', label: 'cellulite molle' },
        { value: '11', label: 'cellulite culotte' },
        { value: '12', label: 'arti superiori linfedema' },
        { value: '13', label: 'arti superiori leggero' },
        { value: '14', label: 'arti superiori intenso' },
        { value: '15', label: 'arti superiori forte' },
      ],
      modalitaLavoroXTOptions: [
        { value: 'tonificante', label: 'Tonificante' },
        { value: 'rassodante', label: 'Rassodante' },
        { value: 'ipertrofia', label: 'Ipertrofia' },
        { value: 'aerobico', label: 'Aerobico' },
        { value: 'modellante', label: 'Modellante' },
      ],
      morfologiaOptions: [
        { value: '0', label: 'Longilineo' },
        { value: '1', label: 'Normolineo' },
        { value: '2', label: 'Brevilineo' },
      ],
      durataOptions: [
        { value: '5', label: '5 minuti' },
        { value: '10', label: '10 minuti' },
        { value: '15', label: '15 minuti' },
        { value: '20', label: '20 minuti' },
        { value: '25', label: '25 minuti' },
        { value: '30', label: '30 minuti' },
      ],
      durataXPOptions: [
        { value: '5', label: '5 minuti' },
        { value: '10', label: '10 minuti' },
        { value: '15', label: '15 minuti' },
        { value: '20', label: '20 minuti' },
        { value: '25', label: '25 minuti' },
        { value: '30', label: '30 minuti' },
        { value: '35', label: '35 minuti' },
        { value: '40', label: '40 minuti' },
        { value: '45', label: '45 minuti' },
        { value: '50', label: '50 minuti' },
        { value: '55', label: '55 minuti' },
        { value: '60', label: '60 minuti' },
      ],
      potenzaOptions: [
        { value: '0', label: '0' },
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
        { value: '70', label: '70' },
        { value: '80', label: '80' },
        { value: '90', label: '90' },
        { value: '100', label: '100' },
      ],
      manipoloTBOptions: [
        { value: 'giallo', label: 'Giallo' },
        { value: 'rosso', label: 'Rosso' },
        { value: 'dermopor', label: 'Verde' },
        { value: 'vacuum', label: 'Blu' },
      ],
      manipoloCTOptions: [
        { value: 'giallo', label: 'Giallo' },
        { value: 'verde', label: 'Verde' },
      ],
      manipoloTFOptions: [
        { value: 'ondadinamica', label: 'Ondadinamica' },
        { value: 'dermoabrasione', label: 'Dermoabrasione' },
        { value: 'dermoporazione', label: 'Dermoporazione' },
        { value: 'dermoregenia', label: 'Dermoregenia' },
      ],
      manipoloFBOptions: [
        { value: 'giallo', label: 'Giallo' },
        { value: 'rosso', label: 'Rosso' },
      ],
      manipoloXTOptions: [
        { value: 'localizzato', label: 'Localizzato' },
        { value: 'bodyshaping', label: 'Bodyshaping' },
      ],
      zonaTBeFBOptions: [
        { value: '6', label: 'addome' },
        { value: '5', label: 'glutei' },
        { value: '4', label: 'cosce' },
        { value: '3', label: 'dorso' },
        { value: '2', label: 'petto' },
        { value: '1', label: 'braccia' },
        { value: '0', label: 'gambe' },
      ],
      zonaXTLocalOptions: [
        { value: '0', label: 'addome' },
        { value: '1', label: 'glutei' },
        { value: '2', label: 'cosce' },
        { value: '5', label: 'braccia' },
        { value: '6', label: 'gambe' },
      ],
      zonaXTBodyshapingOptions: [
        { value: 'parziale', label: 'Parziale' },
        { value: 'totalbody', label: 'Total Body' },
      ],
    };
  },
  methods: {
    getDurataOptions() {
      // Define the conditions to show the Durata options
      if (this.tipoMacchina === 'XP') {
        return this.durataXPOptions;
      }
      return this.durataOptions;
    },
    getManipoloOptions() {
      // Define the conditions to show the Manipolo options
      if (this.tipoMacchina === 'TB') {
        return this.manipoloTBOptions;
      }
      if (this.tipoMacchina === 'CT') {
        return this.manipoloCTOptions;
      }
      if (this.tipoMacchina === 'TF') {
        return this.manipoloTFOptions;
      }
      if (this.tipoMacchina === 'FB') {
        return this.manipoloFBOptions;
      }
      if (this.tipoMacchina === 'XT') {
        return this.manipoloXTOptions;
      }
      return [];
    },
    getZonaOptions() {
      if (this.tipoMacchina === 'TB' || this.tipoMacchina === 'FB') {
        return this.zonaTBeFBOptions;
      }
      if (this.tipoMacchina === 'CT' && this.sedutaProgrammata.manip === 'giallo') {
        return this.zonaTBeFBOptions;
      }

      if (this.tipoMacchina === 'XT') {
        if (this.sedutaProgrammata.manip === 'localizzato') {
          return this.zonaXTLocalOptions;
        }
        if (this.sedutaProgrammata.manip === 'bodyshaping') {
          return this.zonaXTBodyshapingOptions;
        }
      }
      console.error('Zona options not found');
      return [];
    },
    hideZonaField() {
      // Define the conditions to show the Zona field
      return (
        ['TF', 'LT', 'XP'].includes(this.tipoMacchina)
        || (this.tipoMacchina === 'TB' && !['giallo', 'rosso'].includes(this.sedutaProgrammata.manip))
        || (this.tipoMacchina === 'FB' && this.sedutaProgrammata.manip !== 'giallo')
        || (this.tipoMacchina === 'CT' && this.sedutaProgrammata.manip !== 'giallo')
      );
    },
    showDurataField() {
      // Define the conditions to show the Durata field
      return this.tipoMacchina !== 'XT';
    },
    showPotenzaField() {
      // Define the conditions to show the Potenza field
      if (this.tipoMacchina === 'XT' || this.sedutaProgrammata.manip === 'verde' || this.tipoMacchina === 'XP') {
        return false;
      }
      return true;
    },
    showMorfologiaGenereField() {
      // Define the conditions to show the Morfologia and Genere fields
      return (
        (['TB', 'CT', 'BP'].includes(this.tipoMacchina)
          && ['rosso', 'giallo'].includes(this.sedutaProgrammata.manip))
        || (this.tipoMacchina === 'FB'
          && this.sedutaProgrammata.manip === 'giallo')
      );
    },
    showModalitaLavoroField() {
      // Define the conditions to show the Modalità di lavoro field for XT (localizzato)
      return (
        this.tipoMacchina === 'XT'
        && this.sedutaProgrammata.manip === 'localizzato'
      );
    },
    seeDefaultTrattamento() {
      if (!this.sedutaProgrammata.trattamento) {
        return false;
      }
      return true;
    },
    setDefaultTrattamento() {
      const trattamentoParameters = {
        0: { pressione: '10', tenuta: '5', durata: '35' },
        1: { pressione: '9', tenuta: '9', durata: '40' },
        2: { pressione: '9', tenuta: '2', durata: '30' },
        3: { pressione: '10', tenuta: '6', durata: '40' },
        4: { pressione: '10', tenuta: '4', durata: '35' },
        5: { pressione: '11', tenuta: '8', durata: '30' },
        6: { pressione: '10', tenuta: '6', durata: '25' },
        7: { pressione: '11', tenuta: '4', durata: '30' },
        8: { pressione: '10', tenuta: '5', durata: '40' },
        9: { pressione: '9', tenuta: '4', durata: '40' },
        10: { pressione: '10', tenuta: '5', durata: '40' },
        11: { pressione: '9', tenuta: '7', durata: '30' },
        12: { pressione: '11', tenuta: '6', durata: '30' },
        13: { pressione: '10', tenuta: '8', durata: '20' },
        14: { pressione: '11', tenuta: '4', durata: '20' },
        15: { pressione: '11', tenuta: '6', durata: '30' },
      };
      const trattamentoValue = this.sedutaProgrammata.trattamento.toString();
      if (Object.prototype.hasOwnProperty.call(trattamentoParameters, trattamentoValue)) {
        const parameters = trattamentoParameters[trattamentoValue];
        this.sedutaProgrammata.pressione = parameters.pressione;
        this.sedutaProgrammata.tenuta = parameters.tenuta;
        this.sedutaProgrammata.durata = parameters.durata;
      }
    },
    salvaSeduta() {
      if (!this.sedutaProgrammata || this.sedutaProgrammata.durata === null) {
        console.error('Seduta programmata non valida');
        return;
      }
      this.salvaSedutaProgrammata(this.sedutaProgrammata);
    },
  },
  watch: {
    'sedutaProgrammata.trattamento': function () {
      this.setDefaultTrattamento();
    },
  },
};
