
import { defineComponent } from 'vue';
import marked from 'marked';

export default defineComponent({
  name: 'RouterLinkWidget',
  components: {
  },
  props: {
    showEditTools: { default: false },

    config: {
      default: () => ({
        width: 1, classes: '', markdownSource: () => '', to: '',
      }),
      type: Object,
    },
    dbData: { default: () => ({}), type: Object },
  },
  computed: {
    to() {
      return this.config.to(this.dbData) || '';
    },
    markdownHtml() {
      return marked(this.config.markdownSource(this.dbData) || '');
    },
    configClasses() {
      return `md:col-span-${this.config.width} ${this.config.classes || ''}`;
    },
  },
});
