
import { defineComponent } from 'vue';
import Table from '@/components/ui/Table.vue';

export default defineComponent({
  name: 'TableRicaricheEffettuate',
  components: {
    Table,
  },
  props: {
    listaRicaricheEffettuate: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTableData() {
      if (this.listaRicaricheEffettuate.length > 0) {
        return this.listaRicaricheEffettuate;
      }
      return [];
    },
    getTableConfig() {
      return {
        columns: [
          { header: 'Operatore', path: 'utente' },
          { header: 'Data', path: 'dataRicarica' },
          {
            header: 'Ore ricarica',
            path: 'ore_ricarica',
          },
        ],
      };
    },
  },
});
